import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    animationInFinished: false,
    dataFetched: false,
    pageData: null,
    showLoading: true,
    //eslint-disable-next-line
    baseUrl: window.hasOwnProperty('apiURL') ? window.apiURL : 'https://homologcms.neugebauer.com.br/api/',
    hideHeader: false,
    menuOpen: false
  },
  mutations: {
    HIDE_HEADER: (state, data = true) => {
      state.hideHeader = data;
    },
    DATA_FETCHED: (state, data = true) => {
      state.dataFetched = data;
    },
    UPDATE_PAGE_DATA: (state, data) => {
      state.pageData = data;
    },
    ANIMATION_IN_FINISHED: (state, data = true) => {
      state.animationInFinished = data;
    },
    SHOW_LOADING: (state, data) => {
      state.showLoading = data;
    },
    OPEN_MENU: (state, data = true) => {
      state.menuOpen = data;
    }
  },
  actions: {
    LOAD_CONTENT_WITHOUT_REQUEST: ({ commit, state }) => {
      commit("DATA_FETCHED", false);
      commit("ANIMATION_IN_FINISHED", false);
      commit("SHOW_LOADING", true);

      commit("DATA_FETCHED");
      const checkAnimationIn = setInterval(() => {
        if (state.animationInFinished) {
          clearInterval(checkAnimationIn);
        }
      }, 100);

    },
    LOAD_CONTENT: ({ commit, state }, { lang, api }) => {
      commit("DATA_FETCHED", false);
      commit("ANIMATION_IN_FINISHED", false);
      commit("SHOW_LOADING", true);
      
      let dataObj = {};
      const baseUrl = state.baseUrl;
      const requestsList = api.map(apiItem => {
        const url = `${baseUrl}${lang}/${apiItem.resource}${
          apiItem.param ? "/" + apiItem.param : ""
          }`;
        dataObj[apiItem.resource.split("?")[0]] = {};
        return axios.get(url);
      });

      return new Promise(resolve => {
        axios.all(requestsList).then(
          axios.spread((...res) => {
            //Populate data object
            let i = 0;
            for (const key in dataObj) {
              dataObj[key] = res[i++].data;
            }
            commit("UPDATE_PAGE_DATA", dataObj);
            commit("DATA_FETCHED");
            const checkAnimationIn = setInterval(() => {
              if (state.animationInFinished) {
                clearInterval(checkAnimationIn);
                resolve();
              }
            }, 100);
          })
        );
      });
    },
    LOAD_CONTENT_WITH_FLAVOR: ({ commit, state }, { lang, api, flavor }) => {
      commit("DATA_FETCHED", false);
      commit("ANIMATION_IN_FINISHED", false);
      commit("SHOW_LOADING", true);
      let dataObj = {};
      const baseUrl = state.baseUrl;
      const requestsList = api.map(apiItem => {
        const url = `${baseUrl}${lang}/${apiItem.resource}${
          apiItem.param ? "/" + apiItem.param : ""
          }`;
        dataObj[apiItem.resource.split("?")[0]] = {};
        return axios.get(url);
      });
      return new Promise(resolve => {
        axios.all(requestsList).then(
          axios.spread((...res) => {
            //Populate data object
            let i = 0;
            for (const key in dataObj) {
              dataObj[key] = res[i++].data;
            }
            dataObj["selectedFlavor"] = flavor;
            commit("UPDATE_PAGE_DATA", dataObj);
            commit("DATA_FETCHED");
            const checkAnimationIn = setInterval(() => {
              if (state.animationInFinished) {
                clearInterval(checkAnimationIn);
                resolve();
              }
            }, 100);
          })
        );
      });
    }
  }
});
