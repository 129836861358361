<template>
  <div class="featured-news">
    <div class="row flex-sm">
      
      <div class="col offset-xxs-2 offset-sm-2 offset-md-3 offset-xlg-4 xxs-12 sm-5 xlg-4 mb-xxs-4 mb-sm-0">
        
        <h3 class="title-small f-light-brown mb-xxs-3 mb-sm-4 show-xxs hide-sm">
          {{title}}
        </h3>

        <h2 class="super-title featured-news-subtitle mb-xxs-4 mb-xxs-10 mb-sm-20 mb-xlg-16 f-yellow show-xxs hide-sm" >
          {{mainTitle}}
        </h2>

        <div class="square img bg-orange mb-xxs-6 mb-sm-0" :style="{backgroundImage: 'url(' + thumbnail + ')'}" ></div>
      </div>

      <div class="col offset-sm-1 offset-xxs-2 xxs-12 sm-5 valign-bottom">
        <h3 class="title-small f-light-brown mb-sm-4 show-sm hide-xxs">
          {{title}}
        </h3>
        
        <h2 class="super-title featured-news-subtitle mb-xxs-3 mb-sm-20 mb-xlg-16 f-yellow show-sm hide-xxs">
          {{mainTitle}}
        </h2>


        <h4 class="regular-title mb-xxs-8 mb-sm-5 mb-sm-6 f-gray xlg-16">{{content.title}}</h4>
        
        <regular-button
          class="f-orange b-lightest-brown xxs-16 sm-auto"
          hasArrowIcon="true"
          hoverColor="#f15a22"
          :link="`/${this.$i18n.locale}/novidades/${content.slug}`"
        >{{ $t('buttons.access') }}</regular-button>

      </div>

    </div>
  </div>
</template>

<script>
import RegularButton from "../_general/RegularButton";
export default {
  name: "featured-content-1",
  components: {
    RegularButton
  },
  props: ["title", "mainTitle", "content"],
  data: function() {
    return {
      thumbnail: ""
    };
  },
  mounted: function() {
    this.thumbnail = this.content.image.thumbnail.split(" ").join("%20");
  }
};
</script>

<style lang="sass" scoped>
@import '../../assets/sass/neug-grid/_variables'

.regular-title
    @media ($smBreak)
        height: 2.4em

.featured-news
    position: relative
    z-index: 2

.square
    &.img
        padding-top: 0
        height: 200px
        background-repeat: none
        background-size: cover
        background-position: center center
        
        @media ($smBreak)
            padding-top: 120%
            height: 0

        @media ($mdBreak)
            padding-top: 100%
            height: 0

</style>
