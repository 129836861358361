import Vue from "vue";
import Router from "vue-router";
import HomePage from "./views/HomePage.vue";
import store from "./store";


// Suppress the redundant navigation error for 'push'
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// Suppress the redundant navigation error for 'replace'
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/pt"
    },
    {
      path: "*",
      redirect: "/pt/404"
    },
    {
      path: '/:lang/404*',
      component: () => import('./views/NotFound.vue'),
      beforeEnter: (to, from, next) => {

        //Load utilities content - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT_WITHOUT_REQUEST", {
            lang: to.params.lang
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang",
      name: "home",
      component: HomePage, 
      beforeEnter: (to, from, next) => {
        if( to.params.lang != 'pt' && to.params.lang != 'en' && to.params.lang != 'es') {
          next('/pt/404');
        }
        else{
          //Load home content - - - - - - - - - - - - - - - - - - - - - - - - -
          store
            .dispatch("LOAD_CONTENT", {
              lang: to.params.lang,
              api: [
              { resource: "lastPost" },
              { resource: "banners" },
              { resource: "recipes?featured=1" }
              ]
            })
            .then(() => {
              //After content loaded, redirect to page
              next();
            });
          //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
        }
      }
    },
    {
      path: "/:lang/a-marca",
      name: "a-marca",
      component: () => import("./views/About.vue"),
      beforeEnter: (to, from, next) => {
        //Load recipe content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [{ resource: "stores" }, { resource: "storeCities" }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/receitas/:slug",
      name: "receita",
      component: () => import("./views/Recipe.vue"),
      beforeEnter: (to, from, next) => {
        //Load recipe content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [
              {
                resource: "recipeBySlug",
                param: to.params.slug
              }
            ]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/receitas",
      name: "receitas",
      component: () => import("./views/Recipes.vue"),
      beforeEnter: (to, from, next) => {
        //Load recipes content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [{ resource: "recipes?limit=6&start=0" }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/utilidades",
      name: "utilidades",
      component: () => import("./views/Utilities.vue"),
      beforeEnter: (to, from, next) => {
        //Load utilities content - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [{ resource: "faqs" }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/utilidades/:type",
      name: "banco de imagens",
      component: () => import("./views/UtilitiesList.vue"),
      beforeEnter: (to, from, next) => {
        //Define API slug based on URL slug - - - - - - - - - - - - - - - - - -
        let typeSlug;
        if (to.params.type == "banco-de-imagens") {
          typeSlug = "images";
        } else if (to.params.type == "banco-de-arquivos") {
          typeSlug = "files";
        }

        //Load utilities list - - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: typeSlug === 'images' ? 'pt' : to.params.lang,
            api: [{ resource: `${typeSlug}?limit=18&start=0` }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    // {
    //   path: "/:lang/quiosque-bibs",
    //   name: "quiosque bibs",
    //   component: () => import("./views/BibsKiosk.vue"),
    //   beforeEnter: (to, from, next) => {
    //     //FAKE CONTENT
    //     //Load fake content - - - - - - - - - - - - - - - - - - - - - - - -
    //     store
    //       .dispatch("LOAD_CONTENT", {
    //         lang: to.params.lang,
    //         api: [{ resource: "recipes?limit=1&start=0" }]
    //       })
    //       .then(() => {
    //         //After content loaded, redirect to page
    //         next();
    //       });
    //     //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //   }
    // },
    {
      path: "/:lang/novidades/:slug",
      name: "post",
      component: () => import("./views/Article.vue"),
      beforeEnter: (to, from, next) => {
        //Load news content - - - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [{ resource: "faqs" }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/produtos/:category?/:topo?/",
      name: "allProducts",
      component: () => import("./views/Products.vue"),
      beforeEnter: (to, from, next) => {
        
        if (to.path === from.path) {
          return;
        }
        //Load products content - - - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [{ resource: "products" }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/produtos/:category/:subcategory/:slug/:selectedFlavor",
      name: "productFlavorBySlug",
      component: () => import("./views/Product.vue"),
      beforeEnter: (to, from, next) => {
        //Load products content - - - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT_WITH_FLAVOR", {
            lang: to.params.lang,
            api: [
              {
                resource: "productBySlug",
                param:
                  to.params.category +
                  "/" +
                  to.params.subcategory +
                  "/" +
                  to.params.slug
              }
            ],
            flavor: to.params.selectedFlavor
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/produtos/:category/:subcategory/:slug",
      name: "productBySlug",
      component: () => import("./views/Product.vue")
    },
    {
      path: "/:lang/novidades",
      name: "novidades",
      component: () => import("./views/News.vue"),
      beforeEnter: (to, from, next) => {
        //Load news content - - - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [{ resource: "posts" }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/politica-de-privacidade",
      name: "Política de Privacidade",
      component: () => import("./views/PolicyPrivacy.vue"),
      beforeEnter: (to, from, next) => {
        //Load recipe content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT_WITHOUT_REQUEST", {
            lang: to.params.lang,
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/politica-de-privacidade-concurso-cultural",
      name: "Política de Privacidade Concurso Cultural",
      component: () => import("./views/PolicyPrivacyCultural.vue"),
      beforeEnter: (to, from, next) => {
        //Load recipe content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT_WITHOUT_REQUEST", {
            lang: to.params.lang,
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    {
      path: "/:lang/politica-de-cookies",
      name: "Política de Cookies",
      component: () => import("./views/CookiesPolicy.vue"),
      beforeEnter: (to, from, next) => {
        //Load recipe content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT_WITHOUT_REQUEST", {
            lang: to.params.lang,
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    /*{
      path: "/:lang/trabalhe-conosco",
      name: "trabalhe",
      component: () => import("./views/WorkWithUs.vue"),
      beforeEnter: (to, from, next) => {
        //FAKE CONTENT
        //Load fake content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [{ resource: "recipes?limit=1&start=0" }]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },*/
    {
      path: "/:lang/fale-conosco",
      name: "Fale Conosco",
      component: () => import("./views/ContactUs.vue"),
      beforeEnter: (to, from, next) => {
        //Load content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT", {
            lang: to.params.lang,
            api: [
              {resource: "comercialContactsRegions"},
              {resource: "faqs" }
              ]
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },
    // {
    //   path: "/:lang/contatos-comerciais",
    //   name: "Gerentes Comerciais",
    //   component: () => import("./views/CommercialContact.vue"),
    //   beforeEnter: (to, from, next) => {
    //     //Load content - - - - - - - - - - - - - - - - - - - - - - - -
    //     store
    //       .dispatch("LOAD_CONTENT", {
    //         lang: to.params.lang,
    //         api: [{ resource: "comercialContactsRegions" }]
    //       })
    //       .then(() => {
    //         //After content loaded, redirect to page
    //         next();
    //       });
    //     //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //   }
    // },
    // {
    //   path: "/:lang/quero-revender",
    //   name: "Quero revender",
    //   component: () => import("./views/BeASeller.vue"),
    //   beforeEnter: (to, from, next) => {
    //     //Load content - - - - - - - - - - - - - - - - - - - - - - - -
    //     store
    //       .dispatch("LOAD_CONTENT", {
    //         lang: to.params.lang,
    //         api: [{ resource: "comercialContactsRegions" }]
    //       })
    //       .then(() => {
    //         //After content loaded, redirect to page
    //         next();
    //       });
    //     //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    //   }
    // },
    {
      path: "/:lang/promocoes",
      name: "Promoções",
      component: () => import("./views/sale.vue"),
      beforeEnter: (to, from, next) => {
        //Load content - - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT_WITHOUT_REQUEST", {
            lang: to.params.lang
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }


      
    },
    {
      path: "/:lang/festivaldochocolate",
      name: "Festival do Chocolate",
      component: () => import("./views/ChocolateFestival.vue"),
      beforeEnter: (to, from, next) => {

        //Load utilities content - - - - - - - - - - - - - - - - - - - - - - -
        store
          .dispatch("LOAD_CONTENT_WITHOUT_REQUEST", {
            lang: to.params.lang
          })
          .then(() => {
            //After content loaded, redirect to page
            next();
          });
        //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
      }
    },

  ],
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach((to, from, next) => {
  store.commit("OPEN_MENU", false);

  if (to.name === "productBySlug") {
    //Load product content - - - - - - - - - - - - - - - - - - - - - - - - - -
    store
      .dispatch("LOAD_CONTENT", {
        lang: to.params.lang,
        api: [
          {
            resource: "productBySlug",
            param:
              to.params.category +
              "/" +
              to.params.subcategory +
              "/" +
              to.params.slug
          }
        ]
      })
      .then(() => {
        //After content loaded, redirect to page
        next();
      });
    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  } else if (to.name === "productsBySubCategory") {
    //Load products content - - - - - - - - - - - - - - - - - - - - - - - - - -
    store
      .dispatch("LOAD_CONTENT", {
        lang: to.params.lang,
        api: [
          {
            resource: "productsBySubCategory",
            param: to.params.subcategory
          }
        ]
      })
      .then(() => {
        //After content loaded, redirect to page
        next();
      });
    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  } else if (to.name === "productFlavorBySlug" && to.name === from.name && to.path !== from.path) {
    //Load products content - - - - - - - - - - - - - - - - - - - - - - - - - -
    store
      .dispatch("LOAD_CONTENT_WITH_FLAVOR", {
        lang: to.params.lang,
        api: [
          {
            resource: "productBySlug",
            param:
              to.params.category +
              "/" +
              to.params.subcategory +
              "/" +
              to.params.slug
          }
        ],
        flavor: to.params.selectedFlavor
      })
      .then(() => {
        //After content loaded, redirect to page
        next();
      });
    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  } else if (to.name === "productsBySubCategoryBySlug") {
    //Load products content - - - - - - - - - - - - - - - - - - - - - - - - - -
    store
      .dispatch("LOAD_CONTENT", {
        lang: to.params.lang,
        api: [
          {
            resource: "productsBySubCategoryBySlug",
            param: to.params.category + "/" + to.params.subcategory
          }
        ]
      })
      .then(() => {
        //After content loaded, redirect to page
        next();
      });
    //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  } else {
    next();
  }
});

export default router;
