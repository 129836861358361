<template>
    <div class="slides-handler">
        <div class="f-white previous-button handler-btn" @click="this.prev">
            <ArrowRightIcon/>
        </div>
        <div class="f-white next-button handler-btn" @click="this.next">
            <ArrowRightIcon/>
        </div>
    </div>
</template>

<script>
import ArrowRightIcon from '../../assets/imgs/arrow-right-icon.svg'
export default {
    name: 'slides-handler',
    components: {
        ArrowRightIcon
    },
    methods: {
        next: function () {
            this.$emit('nextClicked')
        },
        prev: function () {
            this.$emit('prevClicked')
        }
    }
}
</script>

<style lang="sass" scoped>

.handler-btn
    cursor: pointer
    border: 4px solid #fbe4d2
    transition: all 0.4s ease
    background-color: #fff
    position: relative
    width: 51px
    height: 51px
    @media (min-width: 1200px)
        background-color: transparent
        .slides-handler.c-handler-recipes &
            background-color: #fff

    &:before
        content: ''
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 0%
        -webkit-transition: all 0.5s ease
        transition: all 0.5s ease
        background-color: rgb(241, 90, 34)

    &:hover
        &:before
            top: 0
            left: 0
            height: 100%
        svg
            fill: #fff

.previous-button,
.next-button
    position: absolute
    top: 50%
    transform: translateY(-50%)
    z-index: 5
    svg
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        width: 15%
        height: auto
        fill: rgb(241, 90, 34)
.next-button
     right: -15px
     @media (min-width: 1200px)
        right: -80px
        .slides-handler.c-handler-recipes &
            right: -30px
.previous-button
    left: -15px
    @media (min-width: 1200px)
        left: -80px
        .slides-handler.c-handler-recipes &
            left: -30px
    svg
        transform: translate(-50%, -50%) rotate(180deg)

</style>
