import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './translations/i18n'
import store from './store'
import vueHeadful from 'vue-headful';
import VueScrollTo from 'vue-scrollto';
import VueGtag from "vue-gtag";

Vue.component('vue-headful', vueHeadful);

Vue.use(VueScrollTo)

Vue.use(VueGtag, {
  config: { id: 'UA-99267746-1' },
  includes: [
    { id: 'G-CBNPS0N2W9' }
  ]
}, router);

Vue.config.productionTip = false

new Vue({
  data: {
    loading: false
  },
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
