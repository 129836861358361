<template>  
    <div class="main-banner mb-xxs-15 mb-xs-10 mb-sm-9">
        <div class="slides swiper-container" ref="slides">
            <div class="swiper-wrapper">
                <div v-for="(slide, i) in slides"
                    :key="i"
                    class="swiper-slide bg-orange swiper-no-swiping">
                        <a v-bind:href="slide.link" v-if="slide.link">
                            <div class="desktop" :style="{ backgroundImage: 'url(' + slide.images.desktop.replace(' ', '%20') + ')' }"></div>
                            <div class="tablet" :style="{ backgroundImage: 'url(' + slide.images.tablet.replace(' ', '%20') + ')' }"></div>
                            <div class="phone" :style="{ backgroundImage: 'url(' + slide.images.phone.replace(' ', '%20') + ')' }"></div>
                        </a>
                        <div v-else>
                            <div class="desktop" :style="{ backgroundImage: 'url(' + slide.images.desktop.replace(' ', '%20') + ')' }"></div>
                            <div class="tablet" :style="{ backgroundImage: 'url(' + slide.images.tablet.replace(' ', '%20') + ')' }"></div>
                            <div class="phone" :style="{ backgroundImage: 'url(' + slide.images.phone.replace(' ', '%20') + ')' }"></div>
                        </div>
                </div>
            </div>
            <div class="slides-pagination"></div>
        </div>
    </div>
</template>

<script>
import Swiper, { Pagination } from 'swiper/bundle';
import 'swiper/swiper-bundle.css';

Swiper.use([Pagination]);

export default {
    name: 'main-banner',
    props: ['slides'],
    mounted: function() {
        this.$nextTick(() => {
            new Swiper(this.$refs.slides, {
                direction: 'vertical',
                noSwiping: true,
                pagination: {
                    el: '.slides-pagination',
                    clickable: true
                }
            });
        });
    }
}
</script>

<style lang="sass">
@import '../../assets/sass/neug-grid/_variables'
@import '../../../node_modules/swiper/swiper-bundle.min.css'

.main-banner
    width: 100%
    background-color: #f7911d
    padding-top: 150%
    overflow: hidden
    position: relative

    @media ($mdBreak)
        padding-top: 70%

    @media ($lgBreak)
        padding-top: 50%

.desktop,
.tablet,
.phone
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-size: cover
    background-position: center center
    display: none

@media ($xxsBreak)
    .phone
        display: block

    .tablet,
    .desktop
        display: none

@media ($mdBreak)
    .tablet
        display: block

    .phone,
    .desktop
        display: none

@media ($lgBreak)
    .desktop
        display: block

    .phone,
    .tablet
        display: none

.slides
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.slides-pagination
    position: absolute
    top: 50%
    right: 6.25% !important
    transform: translateY(-50%)
    z-index: 3

.slides-pagination .swiper-pagination-bullet 
    width: 12px
    height: 12px
    margin: 20px 0 !important
    background: #fff !important
    opacity: 1 !important
    

.swiper-container-vertical
    > .swiper-pagination-bullets
        .swiper-pagination-bullet
            background-color: #FFFFFF
            width: 10px
            height: 10px
            margin: 20px 0
            opacity: 1
            display: block

.swiper-pagination-bullet-active
    border: 5px solid #6b3128
    width: 2px !important
    height: 2px !important
    margin-left: -1px !important

</style>

