import Vue from "vue";
import VueI18n from "vue-i18n";

//Set i18n
Vue.use(VueI18n);

const messages = {
  pt: {
    chocolate: "chocolate | chocolates",
    candy: "candy | candies",
    candyStore: "confeitaria | confeitarias",
    confectionery: "confeito | confeitos",
    tablets: "tablete | tabletes",
    bonbons: "bombom | bonbons",
    buttons: {
      seeAllProducts: "Ver todos produtos",
      seeAll: "ver todos",
      knowMore: "Saiba Mais",
      access: "Acessar",
      showMore: "Mostrar Mais",
      back: "Voltar",
      send: "Enviar",
      seeMap: "Ver Mapa",
    },
    kiosk: {
      title1: "Quiosque",
      title2: "Bib's",
      intro: "UMA EXPERIÊNCIA INCRÍVEL PARA DEIXAR O SEU DIA MUITO MAIS DOCE!",
      text1:
        "Os Quiosques Bib’s apresentam uma nova forma de curtir os confeitos mais amados. São diversos sabores exclusivos, como Stikadinho, 70% Cacau, Cheesecake, entre outros... é só escolher o tamanho do copo, misturar quantos sabores quiser e pronto! Descubra como Bib’s pode mudar seu dia! <br/><br/> Os Presenteáveis Neugebauer também estão nos Quiosques. Uma linha inteiramente pensada para quem adora presentear com chocolate! Nossos produtos feitos com o autêntico chocolate Neugebauer em embalagens modernas com um toque especial. <br/><br/> Você ainda encontra os produtos da linha Bib’s que já são sucesso, além de novidades deliciosas!<br/><br/>",
      text2: "Venha conhecer! Esperamos por você!",
      galleryTitle1: "Galeria",
      galleryTitle2: "de fotos",
      findUs: "Onde estamos",
      assistanceTime: "Horário de Atendimento",
      time1: "Segunda a domingo das 10h às 20h",
      time2: "Segunda a sábado das 12h às 20h <br/> Fechado aos domingos",
    },
    menu: {
      title: "Menu",
      brand: "A Marca",
      news: "Novidades",
      kiosk: "Quiosque Bib's",
      recipes: "Receitas",
      utilities: "Utilidades",
      contact: "Fale Conosco",
      work: "Trabalhe Conosco",
      search: "Busca",
      stayInTouch: "Fique por dentro",
      moreFlavor1: "Muito mais",
      moreFlavor2: "Sabor",
      moreFlavor3: "para você",
      followUs1: "Siga a Neugebauer",
      followUs2: "nas Redes Sociais",
      commercialContact: "Contatos Comerciais",
      resell: "Quero Revender",
      policy: "Política de Privacidade",
      cookies: "Política de Cookies",
    },
    brand: {
      title: "A Marca",
      history: "História",
      historyP1:
        "Quando os primeiros imigrantes europeus chegaram ao Brasil, trouxeram consigo a receita do verdadeiro chocolate. Com ela, criamos, em 1891, o chocolate Neugebauer: cremoso, macio, que derrete na boca e tem um sabor inconfundível.",
      historyP2: "Cada pedaço tem um gostinho delicioso e marcante.",
      historySub:
        "Por isso, temos orgulho em dizer que Neugebauer tem os ingredientes perfeitos para quem aprecia o sabor do autêntico chocolate.",
      video1: "Vídeo",
      video2: "institucional",
      videoText:
        "Entre vários outros fatores, o grupo destaca-se por oferecer ao mercado consumidor uma grande variedade de produtos e lançamentos baseados em tecnologia e design inovadores.",
      titleTL: "Venha conosco reviver essa história.",
      video: "https://www.youtube.com/embed/0TRZdrT_8Ow",
      sealText:
        "A Neugebauer detém a Certificação FSSC 22000. Isto reafirma o nosso compromisso com a melhoria contínua e desempenho de negócios sustentáveis. Essa conquista é mais que uma entrada para o comércio. É motivo de orgulho, e demonstra como a Neugebauer trata a qualidade e a segurança de seus produtos.",
    },
    product: {
      unitWeight: "Peso unitário",
      displayWeight: "Peso display",
      displayQuantity: "Quantidade por display",
      presentation: "Apresentação",
      description: "Descrição",
      nutritionTable: "Tabela Nutricional",
      title: "Nossos Produtos",
      subtitle: "Escolha a Categoria",
      indexTab: "Todos os Produtos",
    },
    recipes: {
      title: "Receitas",
      intro1:
        "Receitas de sobremesas, tortas e doces para você fazer em casa com os produtos Neugebauer.",
      intro2: "E a melhor parte: todas têm Neugebauer. Confira.",
      titleCatFilter: "Filtre por categoria",
      defaultCatFilter: "Selecione",
      titleSearchFilter: "Encontre uma receita",
      searchFilter: "Busca",
      orName: "buscar por nome",
      brand: "buscar por marca",
      type: "buscar por tipo de produto",
      cat1: "Sobremesas",
      cat2: "Tortas",
      cat3: "Bebidas",
    },
    utilities: {
      titleFiles1: "Banco de",
      titleFiles2: "arquivos",
      subTitleFiles: "Baixe os arquivos",
      descriptionFiles:
        "Faça o download do nosso catálogo de produtos e marcas.",
      titleImages1: "Banco de",
      titleImages2: "imagens",
      subTitleImages: "Tenha acesso às embalagens dos nossos produtos.",
      titleFAQ1: "Perguntas +",
      titleFAQ2: "frequentes",
      category: "Filtrar por categoria",
      brand: "Filtrar por marca",
      product: "Filtrar por produto",
      material: "Filtrar por material",
      name: "Buscar pelo nome",
      headerDescriptionFiles:
        "Faça o download do nosso catálogo de produtos e marcas.",
      headerDescriptionImages:
        "Tenha acesso às embalagens dos nossos produtos.",
    },
    contact: {
      linguage: "PT",
      subject: "Assunto",
      subjectDescription: "Assunto",
      titleForm: "Entre em contato",
      titleContactUs: "Contato",
      email: "E-mail",
      name: "Nome",
      company: "Empresa",
      cnpj: "Informe o CNPJ do seu negócio",
      phone: "Telefone",
      message: "Mensagem",
      address: "Endereço",
      number: "Número",
      complement: "Complemento",
      city: "Cidade",
      province: "UF",
      product: "Nome do produto",
      weight: "Gramatura/Peso",
      batch: "Lote",
      attach: "Anexar imagem",
      select: "Selecione",
      doubt: "Tenho uma dúvida",
      suggestion: "Tenho uma sugestão",
      category: "Categoria",
      mainContact: "Contato principal",
      project: "Projeto",
      targetAudience: "Público-alvo",
      eventLocation: "Local",
      secondaryContact: "Contato secundário",
      buyAndResell: "Comprar ou revender nossos produtos",
      deliveriesAndlogistics: "Entregas e logística",
      buyProducts: "Comprar produtos",
      resellProducts: "Revender produtos",
      bibsFurniture: "Móvel Bib’s",
      state: "Estado",
      selectUf: "Selecione o estado",
      selectCity: "Selecione a cidade",
      fullName: "Nome completo",
      deliveryIssue: "Sinalizar problema na entrega",
      deliveryForecast: "Previsão de entrega",
      deliveryLocation: "Localização de entrega",
      invoiceNumber: "Número da nota fiscal",
      selectSubject: "Selecione o assunto",
      invociesAndPayments: "Boletos e Pagamentos",
      paymentInfo: "Informação sobre prazo de pagamento de NF",
      secondBillet: "Segunda via de boleto",
      billetExtension: "Prorrogação de boleto",
      refundReturn: "Ressarcimento e devolução",
      jobsVacancies: "Vagas de emprego",
      supplyServices: "Fornecer materiais e serviços",
      rawMaterialsPackaging: "Matéria-Prima/Embalagens",
      marketingPromotionalMaterial: "Material Promocional de Marketing",
      uniformsEPIsLaboratoryMaterial: "Uniformes/EPI’s/Material de laboratório",
      serviceProviderITFinance: "Prestador de serviço/TI/Financeiro",
      equipmentMaintenanceParts: "Equipamentos/Peças de manutenção",
      sponsorshipsDonations: "Patrocínios e doações",
      date: "Data da realização",
      selectProduct: "Selecione o produto*",
      selectBrand: "Selecione a marca*",
      selectGrammage: "Selecione a gramatura*",
      brand: "Marca",
      grammage: "Gramatura",
      resell: "Quero revender",
      supply: "Quero fornecer",
      partnerships: "Parcerias",
      work: "Trabalhe conosco",
      request: "Solicitação",
      compliment: "Elogio",
      complaint: "Reclamação",
      attachment: "Anexo",
      toLocate: "Localizar",
      pointOfSale: "Ponto de venda",
      information: "Informação",
      comercialService: "Atendimento Comercial",
      titleWorkWithUs: "Trabalhe<br> conosco",
      productSingle: "Produto",
      rg: "RG",
      cpf: "CPF/CNPJ",
      jobs:{
        text: "Que legal saber que você deseja fazer parte da Neugebauer!",
        description_one: "Agora a gente conta com um novo sistema de divulgação de vagas, por meio da plataforma Gupy.",
        description_two: "Você pode verificar as oportunidades e registrar seu currículo. ",
        description_three: "e boa sorte.",
        link: "Clique aqui ",
        closing_one: "Nossa história é chocolate. ",
        closing_two: "E queremos que você faça parte dela!",
        hashtags: "#VemSerNeugebauer #NossaHistóriaÉChocolate",
      },
      products: {
        0: "Doce de Leite",
        1: "Bombom",
        2: "Bib's/Confeitos",
        3: "Barras",
        4: "Wafer/Sticks",
      },
      textInfo: {
        title: "Queremos ouvir o que você tem a dizer!",
        subtitle: "Fique atento!",
        link: "Política de Privacidade",
        description_one:
          "Para oferecermos uma experiência mais completa e segura, e manter o histórico de atendimento, vamos solicitar alguns de seus dados pessoais. Por favor, fique por dentro da nossa ",
        description_two:
          " em atendimento à Lei Geral de Proteção de Dados Pessoais (LGPD).",
      },
      validation: {
        cnpj: "CNPJ",
        required: "{field} é obrigatório.",
        email: "{field} deve ser um e-mail válido.",
        subject: "assunto",
        city: "cidade",
        phone: "telefone",
        emailText: "e-mail",
        message: "mensagem",
        invalidFileType: "O {field} deve ser uma imagem, .doc, .docx ou .pdf",
        fileTooLarge: "O {field} selecionado é muito grande."
      },
      channel: {
        title: "Por qual canal você prefere receber um retorno:",
        email: "E-mail",
        phone: "Contato Principal",
        whatsapp: "Contato Secundário",
        additional: "Canal:",
      },
      validTo: "Data da validade",
      zipcode: "CEP",
      corporateName: "Razão Social",
      type: {
        title: "Você é",
        external: "Prestador de serviço",
        supply: "Fornecedor de material",
      },
      workP1:
        "Somos apaixonados pelo que fazemos e adoramos superar expectativas. Nosso maior desafio é a busca constante pela excelência e estamos a procura de mais pessoas que estejam dispostas e preparadas para alcançar o sucesso junto conosco.",
      workP2:
        "O seu perfil integrará o banco de talentos da Neugebauer e assim que houver uma nova oportunidade, analisaremos o seu currículo e poderemos contatar você para uma conversa.",
      sendResume: "Envie seu currículo",
      telTitle: "Contato Neugebauer",
      comercialTitle: "Contato comercial",
      comercialText: "Encontre o contato comercial mais próximo de você",
      checkbox:
        "Estou de acordo que, ao preencher o formulário e enviar meus dados pessoais, estou autorizando a Neugebauer a entrar em contato, pelos meios informados, para retornar minha solicitação.",
      checkRevenda:
        "Por favor, preencha o formulário acima para submeter o seu pedido. Ao nos enviar seus dados, você está de acordo em receber nosso contato através dos meios informados para que possamos ajudá-lo.",
      submitButton: "Enviar",
    },
    comercial: {
      title: "Contatos Comerciais",
    },
    validator: {
      name: "Preencha seu nome",
      company: "Preencha sua empresa",
      cnpj: "Preencha seu CNPJ",
      email: "Preencha seu e-mail",
      phone: "Preencha seu telefone",
      state: "Selecione seu estado",
      city: "Selecione sua cidade",
    },
    seller: {
      title: "Quero revender",
      selectState: "Selecione seu Estado",
      selectCity: "Selecione sua Cidade",
    },
    policy: {
      title: "Política de privacidade",
    },
    cookies: {
      title: "Política de cookies",
    },
  },
  en: {
    buttons: {
      seeAllProducts: "See all products",
      seeAll: "See all",
      knowMore: "Know More",
      access: "Access",
      showMore: "Show More",
      back: "Back",
      send: "Send",
      seeMap: "See Map",
    },
    kiosk: {
      title1: "Bib's",
      title2: "Kiosk",
      intro:
        "Come and visit one of our bib’s kiosks and have an incredible experience to make your day much sweeter!",
      text1:
        "There are 20 flavors of Bib’s, some exclusive like Stikadinho, 70% Cocoa, Macadamia, Hazelnut, Raspberry, Mint, Cherry among others. You choose the size of the glass and can mix as many flavors as you want!",
      text2: "We wait for you!",
      galleryTitle1: "Photo",
      galleryTitle2: "gallery",
      findUs: "Find us",
      assistanceTime: "Assistance Time",
      time1: "From Monday to Sunday, 10pm ‘till 8pm",
      time2: "From Monday to Saturday, 12pm ‘till 20pm <br/> Closed on Sundays",
    },
    menu: {
      title: "Menu",
      brand: "The Brand",
      news: "News",
      kiosk: "Bib's Kiosk",
      recipes: "Recipes",
      utilities: "Utilities",
      contact: "Contact us",
      work: "Work with us",
      search: "Search",
      stayInTouch: "Stay in touch",
      moreFlavor1: "Much more",
      moreFlavor2: "Flavor",
      moreFlavor3: "to you",
      followUs1: "Follow us on",
      followUs2: "our social medias",
      commercialContact: "Business Contact",
      resell: "I want to resell",
      policy: "Privacy policy",
      cookies: "Cookies Policy",
    },
    brand: {
      title: "The brand",
      history: "History",
      historyP1:
        "When the first immigrants arrived in Brazil, they brought a real chocolate recipe with them. And with it, in 1891, we created Neugebauer chocolate: creamy and soft, it melts in your mouth and has a unique flavor.",
      historyP2: "Each piece has a delicious and striking taste.",
      historySub:
        "For this reason, we are proud to say that Neugebauer has the perfect ingredients for those who appreciate the authentic flavor of chocolate.",
      video1: "Institutional",
      video2: "Video",
      videoText:
        "Among other factors, the group stands out by offering the consumer market a wide variety of products and launches based on innovative technology and design.",
      titleTL: "Come with us and relive this history.",
      video: "https://www.youtube.com/embed/fBEs_XqgDG0",
      sealText:
        "Neugebauer is certified by FSSC 22000. This certification reaffirms our commitment to continuous improvement and sustainable business performance. This achievement is more than an entrance to commerce, it is a point of pride, and it demonstrates how Neugebauer treats the quality and safety of its products.",
    },
    product: {
      unitWeight: "Unit weight",
      displayWeight: "Display weight",
      displayQuantity: "Display quantity",
      presentation: "Presentation",
      description: "Description",
      nutritionTable: "Nutrition Facts",
      title: "Our products",
      subtitle: "Choose the category",
      indexTab: "All products",
    },
    recipes: {
      title: "Recipes",
      intro1:
        "Desserts, pies and confectionery recipes for you to make at home with Neugebauer products.",
      intro2: "And the best part: they all have Neugebauer. Check it out.",
      titleCatFilter: "Filter by category",
      defaultCatFilter: "Select",
      titleSearchFilter: "Find a recipe",
      searchFilter: "Search",
      orName: "search by name",
      brand: "search by brand",
      type: "search by product type",
      cat1: "Dessert",
      cat2: "Cakes",
      cat3: "Beverages",
    },
    utilities: {
      titleFiles1: "File",
      titleFiles2: "Stock",
      subTitleFiles: "Download the files",
      descriptionFiles:
        "Product catalog, merchandising guide and product portfolio, institutional video",
      titleImages1: "Image",
      titleImages2: "Bank",
      subTitleImages: "Download the files",
      descriptionImages: "Packaging and logos",
      titleFAQ1: "Common",
      titleFAQ2: "Questions",
      category: "Filter by category",
      brand: "Filter by brand",
      product: "Filter by product",
      material: "Filter by material",
      name: "Search by name",
      headerDescriptionFiles:
        "Download our Product’s Catalog, Logos and Institutional Video.",
      headerDescriptionImages: "Get access to our products packaging.",
    },
    contact: {
      linguage: "EN",
      titleForm: "Contact Us",
      titleContactUs: "Contact us",
      email: "E-mail address",
      name: "Name",
      company: "Company",
      cnpj: "Identity Card",
      phone: "Cell Phone",
      message: "Message",
      address: "Address",
      number: "Number",
      complement: "Complement",
      city: "City",
      province: "Province",
      product: "Product",
      weight: "Grammage/Weight",
      batch: "Batch/Lot",
      attach: "Attach Image",
      select: "Select",
      suggestion: "Suggestion",
      request: "Request",
      compliment: "Compliment",
      complaint: "Complaint",
      attachment: "File",
      toLocate: "Search",
      zipcode: "Zip Code",
      validTo: "Valid to",
      titleWorkWithUs: "Work<br>with us",
      workP1:
        "We are passionate about what we do and love to exceed expectations. Our greatest challenge is the constant pursuit of excellence and we are looking for more people who are willing and prepared to succeed together with us.",
      workP2:
        "Your profile will be part of the Neugebauer talent pool and as soon as there is a new opportunity, we will review your resume and we will be able to contact you for a chat.",
      sendResume: "Submit your resume",
      telTitle: "Contact Neugebauer",
      comercialTitle: "Business contact",
      comercialText: "Find the business contact nearest to you",
      checkbox:
        "I agree to share my personal information with Neugebauer, authorizing them to contact me in order to respond to my message",
      checkRevenda:
        "I agree to share my personal information with Neugebauer, authorizing them to contact me in order to respond to my message",

      subjectDescription: "Subject",
      subject: "Subject",
      doubt: "I have a question",

      category: "Category",
      mainContact: "Main contact",
      project: "Project",
      targetAudience: "Target audience",
      eventLocation: "Place",
      secondaryContact: "Secondary contact",
      buyAndResell: "Buy or resell our products",
      deliveriesAndlogistics: "Deliveries and logistics",
      buyProducts: "Buy our products",
      resellProducts: "Resell our products",
      bibsFurniture: "Bib’s Movable",
      corporateName: "Corporate Name",
      state: "State",
      selectUf: "Select state",
      selectCity: "Select city",
      fullName: "Full name",
      deliveryIssue: "Delivery problem",
      deliveryForecast: "Delivery prevision",
      deliveryLocation: "Delivery location",
      invoiceNumber: "Number of the Nota Fiscal",
      selectSubject: "Select subject",
      invociesAndPayments: "Bank transfer and payment",
      paymentInfo: "Information concerning the Nota Fiscal payment deadline",
      secondBillet: "Second copy of the bank transfer",
      billetExtension: "Extension of the deadline",
      refundReturn: "Refund and devolution",
      jobsVacancies: "Jobs opportunities",
      supplyServices: "Provide materials and Other services",
      rawMaterialsPackaging: "Raw materials/Packaging",
      marketingPromotionalMaterial: "Promotional Marketing Material",
      uniformsEPIsLaboratoryMaterial: "Uniforms/PPE’s/Laboratory Equipment",
      serviceProviderITFinance: "Service providers/IT/Financial",
      equipmentMaintenanceParts: "Equipment/Maintenance parts",
      sponsorshipsDonations: "Sponsorships and donations",
      date: "Date of event",
      selectProduct: "Select the product*",
      selectBrand: "Select the brand*",
      selectGrammage: "Select the grammage*",
      brand: "Brand",
      grammage: "Grammage",
      resell: "I want to resell",
      supply: "I want to supply",
      partnerships: "Partnerships",
      work: "Work with us",
      pointOfSale: "Point of sale",
      information: "Information",
      comercialService: "Commercial Service",
      productSingle: "Product",
      rg: "RG",
      cpf: "Identity Card",
      jobs:{
        text: "We’re more than happy to know that you want to become part of Neugebauer!",
        description_one: "Our job opportunities are available at the website Gupy.",
        description_two: "There you can check our job offers and register your resumé. ",
        description_three: "and good luck!",
        link: "Click here ",
        closing_one: "Our history is chocolate. ",
        closing_two: "And we want you to be a part of it!",
        hashtags: "#VemSerNeugebauer #VemSerNeugebauer ",
      },
      products: {
        0: "Dulce de Leche",
        1: "Bonbon",
        2: "Bib's/Dragée",
        3: "Bars",
        4: "Wafer/Sticks",
      },
      textInfo: {
        title: "We want to hear what you have to say!",
        subtitle: "Stay tuned!",
        link: "Privacy Policy",
        description_one:
          "In order to offer a more complete and secure experience, as well as maintain our service history, we request some of your personal information. Please, get to know our ",
        description_two:
          " according to the General Data Protection Law (LGPD).",
      },
      validation: {
        cnpj: "CNPJ",
        required: "{field} is required.",
        email: "{field} must be a valid email.",
        subject: "subject",
        city: "city",
        phone: "phone",
        emailText: "email",
        message: "message",
        invalidFileType: "{field} must be an image, .doc, .docx, or .pdf",
        fileTooLarge: "The selected {field} is too large."
      },
    },
    comercial: {
      title: "Business Contact",
    },
    validator: {
      name: "Name is required",
      company: "Company is required",
      cnpj: "CNPJ is required",
      email: "Email is required",
      phone: "Phone is required",
      state: "Province is required",
      city: "City is required",
    },
    seller: {
      title: "Be a Seller",
      selectState: "Select your province",
      selectCity: "Select your city",
    },
    policy: {
      title: "Privacy Policy",
    },
    cookies: {
      title: "Cookies policy",
    },
  },
  es: {
    buttons: {
      seeAllProducts: "Ver todos los productos",
      seeAll: "Ver todos",
      knowMore: "Sepa Más",
      access: "Acessar",
      showMore: "Mostrar Más",
      back: "Volver",
      send: "Enviar",
      seeMap: "Ver Mapa",
    },
    kiosk: {
      title1: "Quiosco",
      title2: "Bib's",
      intro:
        "¡Venga a conocer uno de nuestros quioscos bib’s y tenga una experiencia increíble para dejar su día mucho más dulce!",
      text1:
        "Son 20 sabores de Bib’s, algunos exclusivos como el Stikadinho, 70% Cacao, Macadamia, Avellana, Frambuesa, Menta, Cereza entre otros. ¡Usted elige el tamaño de la taza y puede mezclar cuántos sabores usted desea!",
      text2: "¡Les esperamos!",
      galleryTitle1: "Galería",
      galleryTitle2: "de fotos",
      findUs: "Donde estamos",
      assistanceTime: "Horario de Atención",
      time1: "De lunes a domingo desde las 10h hasta las 20h",
      time2:
        "De lunes a sábado desde las 12h hasta las 20h <br/> Cerrado los domingos",
    },
    menu: {
      title: "Menu",
      brand: "La Marca",
      news: "Noticias",
      kiosk: "Quiosco Bib's",
      recipes: "Recetas",
      utilities: "Utilidades",
      contact: "Hable con nosotros",
      work: "Trabaja con nosotros",
      search: "Busca",
      stayInTouch: "manténgase informado",
      moreFlavor1: "Mucho más",
      moreFlavor2: "Sabor",
      moreFlavor3: "para usted",
      followUs1: "Síguenos en nuestras",
      followUs2: "Redes sociales",
      commercialContact: "Contacto Comercial",
      resell: "Quiero revender",
      policy: "Política de privacidad",
      cookies: "Política de Cookies",
    },
    brand: {
      title: "La Marca",
      history: "Historia",
      historyP1:
        "Cuando los primeros inmigrantes europeos llegaron a Brasil, trajeron consigo la receta del verdadero chocolate. Con ella, creamos, en 1891, el chocolate Neugebauer: cremoso, blando, que se derrite en la boca y tiene un sabor inconfundible.",
      historyP2: "Cada pedazo tiene un gusto delicioso y fuerte.",
      historySub:
        "Por eso, tenemos orgullo en decir que Neugebauer tiene los ingredientes perfectos para quien aprecia el sabor del auténtico chocolate.",
      video1: "Video",
      video2: "institucional",
      videoText:
        "Entre varios otros factores, el grupo se destaca por ofrecer al mercado consumidor una gran variedad de productos y lanzamientos basados ​​en tecnología y diseño innovadores.",
      titleTL: "Venga con nosotros a revivir esa historia.",
      video: "https://www.youtube.com/embed/H66NeBL6CPQ",
      sealText:
        "Neugebauer posee la certificación FSSC 22000. Esto reafirma nuestro compromiso con la continua mejora y con el desempeño de negocios sostenibles. Este logro es más que una entrada al comercio; es razón de orgullo; y demuestra cómo Neugebauer trata la calidad y la seguridad de sus produtos.",
    },
    product: {
      unitWeight: "Peso unitario",
      displayWeight: "Peso del empaque",
      displayQuantity: "Cantidad por empaque",
      presentation: "Presentación",
      description: "Descripción",
      nutritionTable: "Información Nutricional",
      title: "Nuestros productos",
      subtitle: "Elige la categoría",
      indexTab: "Todos los productos",
    },
    recipes: {
      title: "Recetas",
      intro1:
        "Recetas de postres, pasteles y dulces para que hagas en casa con los productos Neugebauer.",
      intro2: "Y la mejor parte: todas tienen Neugebauer. Comprobalo.",
      titleCatFilter: "Filtrar por categoría",
      defaultCatFilter: "Selecione",
      titleSearchFilter: "Encuentre una receta",
      searchFilter: "Busca",
      orName: "buscar por nombre",
      brand: "buscar por marca",
      type: "buscar por tipo de producto",
      cat1: "Postre",
      cat2: "Tortas",
      cat3: "Bebidas",
    },
    utilities: {
      titleFiles1: "Banco de",
      titleFiles2: "archivos",
      subTitleFiles: "Descargar los archivos",
      descriptionFiles:
        "Catálogo de productos, guía de merchandising y portafolio de productos, video institucional",
      titleImages1: "Banco de",
      titleImages2: "imagenes",
      subTitleImages: "Descargar los archivos",
      descriptionImages: "Embalajes y logotipos",
      titleFAQ1: "Perguntas",
      titleFAQ2: "frecuentes",
      category: "Filtrar por categoría",
      brand: "Filtrar por marca",
      product: "Filtrar por producto",
      material: "Filtrar por material",
      name: "Buscar por nombre",
      headerDescriptionFiles:
        "Descargue nuestro catálogo de productos, Logotipos y vídeo institucional.",
      headerDescriptionImages: "Acceda a los embalajes de nuestros productos.",
    },
    contact: {
      linguage: "ES",
      subject: "Asunto",
      subjectDescription: "Asunto",
      titleForm: "Póngase en contacto",
      titleContactUs: "Contacto",
      email: "Correo electrónico",
      name: "Nombre",
      company: "Empresa",
      cnpj: "Documento de Identidad",
      phone: "Teléfono",
      message: "Mensaje",
      address: "Dirección",
      number: "Número",
      complement: "Complemento",
      zipcode: "Código Postal",
      validTo: "Válido hasta",
      city: "Ciudad",
      province: "Estado",
      product: "Producto",
      weight: "Gramaje/Peso",
      batch: "Lote",
      attach: "Adjuntar imagen",
      select: "Seleccione",
      doubt: "Tengo una duda",
      suggestion: "Sugerencia",
      category: "Categoría",
      mainContact: "Contacto principal",
      project: "Proyecto",
      targetAudience: "Audiencia objetivo",
      eventLocation: "Ubicación",
      secondaryContact: "Contacto secundario",
      buyAndResell: "Comprar o revender nuestros productos",
      deliveriesAndlogistics: "Entregas y logística",
      buyProducts: "Comprar nuestros productos",
      resellProducts: "Revender nuestros productos",
      bibsFurniture: "Bib’s móvil",
      state: "Estado",
      selectUf: "Seleccione el estado",
      selectCity: "Seleccione la ciudad",
      fullName: "Nombre completo",
      deliveryIssue: "Reportar un problema de entrega",
      deliveryForecast: "Previsión de entrega",
      deliveryLocation: "Ubicación de entrega",
      invoiceNumber: "Numero de la Nota Fiscal",
      selectSubject: "Seleccione el asunto",
      invociesAndPayments: "Boletos de pago y pagamentos",
      paymentInfo: "Información sobre el plazo de pagamento de la Nota Fiscal",
      secondBillet: "Segunda vía del boleto de pago",
      billetExtension: "Prorrogación del plazo del boleto",
      refundReturn: "Resarcimiento y devolución",
      jobsVacancies: "Ofertas de empleo",
      supplyServices: "Suministrar materiales y servicios",
      rawMaterialsPackaging: "Materia prima/Embalaje",
      marketingPromotionalMaterial: "Materiales Promocionales y de Marketing",
      uniformsEPIsLaboratoryMaterial: "Uniformes/PPE’s/Material de laboratorio",
      serviceProviderITFinance: "Proveedor de servicios/Informática y Tecnología/Financiero",
      equipmentMaintenanceParts: "Equipos/Piezas de mantenimiento",
      sponsorshipsDonations: "Patrocinios y donaciones",
      date: "Fecha de realización",
      selectProduct: "Seleccione el producto*",
      selectBrand: "Seleccione la marca*",
      selectGrammage: "Seleccione el gramaje*",
      brand: "Marca",
      grammage: "Gramaje",
      resell: "Quiero revender",
      supply: "Quiero proveer",
      partnerships: "Colaboraciones",
      work: "Trabaja con nosotros",
      request: "Solicitud",
      compliment: "Alabanza",
      complaint: "Reclamo",
      attachment: "Anexo",
      toLocate: "Localizar",
      corporateName: "Razón social",
      pointOfSale: "Punto de venda",
      information: "Información",
      comercialService: "Atención comercial",
      titleWorkWithUs: "Trabaja con<br>nosotros",
      productSingle: "Producto",
      rg: "RG",
      cpf: "Documento de Identidad",
      jobs:{
        text: "¡Nos contenta mucho saber que usted quiere hacer parte de Neugebauer!",
        description_one: "Ahora nosotros usamos un nuevo sistema de divulgación de ofertas de empleo, a través de la plataforma Gupy.",
        description_two: "Usted puede conocer más las ofertas y registrar su currículum. ",
        description_three: "y buena suerte.",
        link: "Clique aquí ",
        closing_one: "Nuestra historia es chocolate. ",
        closing_two: "¡Y queremos que usted sea parte de ella!",
        hashtags: "",
      },
      products: {
        0: "Dulce de Leche",
        1: "Bombóm",
        2: "Bib's/Confites",
        3: "Tabletas",
        4: "Wafer/Sticks",
      },
      textInfo: {
        title: "¡Queremos escuchar lo que tienes que decir!",
        subtitle: "¡Esté atento!",
        link: "Política de Privacidad",
        description_one: "Para ofrecerlos una experiencia más completa y segura, y mantener nuestro histórico de atendimiento, vamos a solicitar algunos se sus datos personales. Por favor, manténgase informado acerca de nuestra ",
        description_two: ", debido a Ley General de Protección de Datos (LGPD)."
      },
      validation: {
        cnpj: "CNPJ",
        required: "{field} es obligatorio.",
        email: "{field} debe ser un correo electrónico válido.",
        subject: "asunto",
        city: "ciudad",
        phone: "teléfono",
        emailText: "correo electrónico",
        message: "mensaje",
        invalidFileType: "{field} debe ser una imagen, .doc, .docx o .pdf",
        fileTooLarge: "El {field} seleccionado es demasiado grande."
      },
      workP1: "Somos apasionados por lo que hacemos y adoramos superar expectativas. Nuestro mayor desafío es la búsqueda constante por la excelencia y estamos buscando a más personas que estén dispuestas y preparadas para alcanzar el éxito con nosotros.",
      workP2: "Su perfil integrará el banco de talentos de Neugebauer y así que haya una nueva oportunidad, analizaremos su currículum y podremos contactar con usted para una conversación.",
      sendResume: "Envíe su currículum",
      telTitle: "Contacto Neugebauer",
      comercialTitle: "Contacto comercial",
      comercialText: "Encuentre el contacto comercial más cercano de usted.",
      checkbox: "Estoy de acuerdo que, al rellenar el formulario y enviar mis datos personales, estoy autorizando a Neugebauer a ponerse en contacto por los medios informados, para contestar mi solicitud.",
      checkRevenda: "Estoy de acuerdo que, al rellenar el formulario y enviar mis datos personales, estoy autorizando a Neugebauer a ponerse en contacto por los medios informados, para contestar mi solicitud."
    },
    comercial: {
      title: "Contacto Comercial",
    },
    validator: {
      name: "Nombre es requerido",
      company: "Empresa es requerido",
      cnpj: "CNPJ es requerido",
      email: "E-mail es requerido",
      phone: "Teléfono es requerido",
      state: "Estado es requerido",
      city: "Ciudad es requerida",
    },
    seller: {
      title: "Quiero revender",
      selectState: "Seleccione tu Estado",
      selectCity: "Seleccione tu Ciudad",
    },
    policy: {
      title: "Política de Privacidad",
    },
    cookies: {
      title: "Política de cookies",
    },
  },
};

export default new VueI18n({
  locale: "pt",
  messages,
});
