<template>
  <div class="featured-products mb-xxs-8 mb-md-9" :class="selectedId">
    <div class="row flex-sm">
      <div
        class="col offset-xxs-2 offset-sm-1 offset-xlg-2 xxs-12 sm-8 md-6 xlg-5 mb-xxs-12 mb-xs-9 mb-sm-0 valign-middle-md"
      >
        <h2 class="title-small mb-xxs-4 f-orange">{{textContent[$i18n.locale].title}}</h2>
        <ul class="product-selector">
          <li
            v-for="(product, i) in textContent[$i18n.locale].products"
            :key="i"
            class="super-title f-light-gray pointer f-transition"
            :class="[hoverClasses[i], activeClass(i)]"
            @click="transitionEnd && defineActive(i)"
          >{{product.name}}</li>
        </ul>
      </div>

      <div
        class="col offset-xxs-1 xxs-14 md-6 xlg-5"
        :class="{ 'transition-in' : transitionIn, 'transition-out' : transitionOut, 'transition-back' : transitionBack }"
      >
        <div class="box">

          <img
            alt="Confeitos"
            class="product-image"
            :class="imgActiveClass(0)"
            src="../../assets/imgs/products-home/confeitos.png"
          >
          <img
            alt="Tabletes"
            class="product-image"
            :class="imgActiveClass(1)"
            src="../../assets/imgs/products-home/tabletes.png"
          >
          <img
            alt="Bombons"
            class="product-image"
            :class="imgActiveClass(2)"
            src="../../assets/imgs/products-home/bombons.png"
          >
          <img
            alt="Snacks"
            class="product-image"
            :class="imgActiveClass(3)"
            src="../../assets/imgs/products-home/snacks.png"
          >
          <img
            alt="Formato Especial"
            class="product-image"
            :class="imgActiveClass(4)"
            src="../../assets/imgs/products-home/formato-especial.png"
          >
          <img
            alt="Doce de Leite"
            class="product-image"
            :class="imgActiveClass(5)"
            src="../../assets/imgs/products-home/doce-de-leite.png"
          >
          <!-- <img
            alt="Pirulitos"
            class="product-image"
            :class="imgActiveClass(6)"
            src="../../assets/imgs/products-home/pirulitos.png"
          > -->
          <img
            alt="Bombons Confeitaria"
            class="product-image"
            :class="imgActiveClass(10)"
            src="../../assets/imgs/products-home/bombons-confeitaria.png"
          >

          <img
            alt="Doce de Leite"
            class="product-image"
            :class="imgActiveClass(11)"
            src="../../assets/imgs/products-home/balde-mumu.png"
          >

          <img
            alt="Doce de Leite"
            class="product-image"
            :class="imgActiveClass(6)"
            src="../../assets/imgs/products-home/mu-mu-gold.png"
          >

          <ul class="category-selector">
            <li
              v-for="(category, i) in categories"
              :key="i"
              class="pointer"
              :class="hoverClass(i)"
              @mouseover="defineHovered(i, category.id)"
              @click="defineHovered(i, category.id)"
            >{{category.name}}</li>
          </ul>
          <div class="u-text-center o-button-wrapper">
            <regular-button class="f-white b-white xxs-16 sm-auto u-text-center" hasArrowIcon="true" hoverColor="#f15a22" :link="`/${$i18n.locale}/produtos/${textContent[$i18n.locale].products[selectedIndex].id}/topo`" >
              {{ $t('buttons.seeAllProducts') }}
            </regular-button>  
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RegularButton from "../_general/RegularButton";
export default {
  name: "featured-products",
  components: {
    RegularButton
  },
  data: function() {
    return {
      hoverClasses: ["hover-f-brown", "hover-f-orange", "hover-f-yellow"],
      selectedIndex: 0,
      clickedIndex: 0,
      selectedId: null,
      categories: [],
      hoveredIndex: 0,
      hoveredId: "confeitos",
      transitionIn: false,
      transitionOut: false,
      transitionEnd: true,
      transitionBack: false,
      textContent: {
        pt: {
          products: [
            {
              id: "chocolates",
              name: "Chocolates",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "confeitos",
                  id_cat: "chocolates",
                  name: "Confeitos",
                  link: "/produtos/chocolates/confeitos"
                },
                {
                  id: "tabletes",
                  id_cat: "chocolates",
                  name: "Tabletes",
                  link: "/produtos/chocolates/tabletes"
                },
                {
                  id: "bombons",
                  id_cat: "chocolates",
                  name: "Bombons",
                  link: "/produtos/chocolates/bombons"
                },
                {
                  id: "snacks",
                  id_cat: "chocolates",
                  name: "Snacks",
                  link: "/produtos/chocolates/snacks"
                },
                {
                  id: "formato-especial",
                  id_cat: "chocolates",
                  name: "Formato Especial",
                  link: "/produtos/chocolates/formato-especial"
                }
              ]
            },
            {
              id: "doce-de-leite",
              name: "Doce de Leite",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "mumu",
                  id_cat: "doce-de-leite",
                  name: "Mu-mu",
                  link: "/produtos/doce-de-leite/mu-mu"
                },
                {
                  id: "mumu-gold",
                  id_cat: "doce-de-leite",
                  name: "Mu-mu Gold",
                  link: "/produtos/doce-de-leite/mu-mu-gold"
                },
              ]
            },
            {
              id: "confeitaria",
              name: "Confeitaria",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "bombons",
                  id_cat: "confeitaria",
                  name: "Bombons",
                  link: "/produtos/confeitaria/bombons"
                },
                {
                  id: "doce-leite",
                  id_cat: "confeitaria",
                  name: "Doce de leite",
                  link: "/produtos/confeitaria/doce"
                }
              ]
            }
          ],
          title: "Nossos produtos"
        },
        en: {
          products: [
            {
              id: "chocolates",
              name: "Chocolates",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "confectionery",
                  id_cat: "chocolates",
                  name: "Confectionery",
                  link: "/produtos/chocolates/confectionery"
                },
                {
                  id: "bars",
                  id_cat: "chocolates",
                  name: "Bars",
                  link: "/produtos/chocolates/bars"
                },
                {
                  id: "bonbons",
                  id_cat: "chocolates",
                  name: "Bonbons",
                  link: "/produtos/chocolates/bonbons"
                },
                {
                  id: "snacks",
                  id_cat: "chocolates",
                  name: "Snacks",
                  link: "/produtos/chocolates/snacks"
                },
                {
                  id: "special-format",
                  id_cat: "chocolates",
                  name: "Especial Format",
                  link: "/produtos/chocolates/special-format"
                },
              ]
            },
            {
              id: "dulce-de-leche",
              name: "Dulce de Leche",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "mumu",
                  id_cat: "dulce-de-leche",
                  name: "Mu-mu",
                  link: "/produtos/dulce-de-leche/mu-mu"
                },
                {
                  id: "mumu-gold",
                  id_cat: "dulce-de-leche",
                  name: "Mu-mu Gold",
                  link: "/produtos/dulce-de-leche/mu-mu-gold"
                },
              ]
            },
            {
              id: "bakery",
              name: "Bakery",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "bonbons",
                  id_cat: "bakery",
                  name: "Bonbons",
                  link: "/produtos/bakery/bonbons"
                },
                {
                  id: "dulce-de-leche",
                  id_cat: "bakery",
                  name: "Dulce de Leche",
                  link: "/produtos/bakery/dulce-de-leche"
                }
              ]
            }
          ],
          title: "Our products"
        },
        es: {
          products: [
            {
              id: "chocolates",
              name: "Chocolates",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "confites",
                  id_cat: "chocolates",
                  name: "Confites",
                  link: "/produtos/chocolates/confites"
                },
                {
                  id: "tabletas",
                  id_cat: "chocolates",
                  name: "Tabletas",
                  link: "/produtos/chocolates/tabletas"
                },
                {
                  id: "bombones",
                  id_cat: "chocolates",
                  name: "Bombones",
                  link: "/produtos/chocolates/bombones"
                },
                {
                  id: "snacks",
                  id_cat: "chocolates",
                  name: "Snacks",
                  link: "/produtos/chocolates/snacks"
                },
                {
                  id: "formato-especial",
                  id_cat: "chocolates",
                  name: "Formato Especial",
                  link: "/produtos/chocolates/formato-especial"
                },
              ]
            },
            {
              id: "dulce-de-leche",
              name: "Dulce de Leche",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "mumu",
                  id_cat: "dulce-de-leche",
                  name: "Mu-mu",
                  link: "/produtos/dulce-de-leche/mu-mu"
                },
                {
                  id: "mumu-gold",
                  id_cat: "dulce-de-leche",
                  name: "Mu-mu Gold",
                  link: "/produtos/dulce-de-leche/mu-mu-gold"
                },
              ]
            },
            {
              id: "confiteria",
              name: "Confitería",
              image: "bibs-ao-leite-pacote40g.png",
              categories: [
                {
                  id: "bombones",
                  id_cat: "confiteria",
                  name: "Bombones",
                  link: "/produtos/confiteria/bombones"
                },
                {
                  id: "dulce-de-leche",
                  id_cat: "confiteria",
                  name: "Dulce de Leche",
                  link: "/produtos/confiteria/dulce-de-leche"
                }
              ]
            }
          ],
          title: "Nuestros productos"
        }
      }
    };
  },
  methods: {
    defineActive: function(i) {
      this.transitionBack = false;
      this.transitionEnd = false;
      this.transitionIn = true;
      setTimeout(() => {
        this.updateActive(i);
        this.hoveredId = this.textContent[this.$i18n.locale].products[
          i
        ].categories[0].id;
        this.transitionOut = true;
      }, 500);
      setTimeout(() => {
        this.updateActive(i);
        this.transitionBack = true;
        this.transitionOut = false;
        this.transitionIn = false;
      }, 1000);
      setTimeout(() => {
        this.transitionEnd = true;
      }, 1300);
    },
    updateActive: function(i) {
      this.clickedIndex = i;
      this.selectedIndex = i;
    },
    activeClass: function(i) {
      if (i == this.clickedIndex) return "active";
    },
    imgActiveClass: function(id) {
      // if (id == this.hoveredId) return "active";
      if (id == this.hoveredIndex + 5 * this.selectedIndex) return "active";
    },
    defineHovered: function(i, id) {
      this.hoveredIndex = i;
      this.hoveredId = id;
    },
    goToSubCat: function(category) {
      this.$router.push(
        `${this.$i18n.locale}/produtos/${category.id_cat}/${category.id}`
      );
    },
    hoverClass: function(i) {
      if (i == this.hoveredIndex) return "hovered";
    }
  },
  mounted: function() {
    this.selectedId = this.textContent[this.$i18n.locale].products[
      this.selectedIndex
    ].id;
    this.categories = this.textContent[this.$i18n.locale].products[
      this.selectedIndex
    ].categories;
  },
  watch: {
    selectedIndex: function() {
      this.selectedId = this.textContent[this.$i18n.locale].products[
        this.selectedIndex
      ].id;
      this.categories = this.textContent[this.$i18n.locale].products[
        this.selectedIndex
      ].categories;
    }
  }
};
</script>

<style lang="sass" scoped>
@import '../../assets/sass/neug-grid/_variables'

.super-title  
    font-size: clamp(25px, 5vw, 64px)

    @media (max-width: 1366px)
      font-size: clamp(25px, 5vw, 54px)

    @media (max-width: 767px)
      font-size: clamp(18px, 5vw, 28px)

    @media (max-width: 575px)
      font-size: clamp(30px, 5vw, 40px)


.featured-products
    position: relative
    overflow-x: hidden
    padding-bottom: 5%

    @media ($smBreak)
        padding-bottom: 0
        overflow: visible
        padding-bottom: 0

    &.chocolates
        .active
            color: #6b3128
        .box
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6b3127+0,55271f+100 */
            background: rgb(107,49,39)
            background: -moz-linear-gradient(top, rgba(107,49,39,1) 0%, rgba(85,39,31,1) 100%)
            background: -webkit-linear-gradient(top, rgba(107,49,39,1) 0%,rgba(85,39,31,1) 100%)
            background: linear-gradient(to bottom, rgba(107,49,39,1) 0%,rgba(85,39,31,1) 100%)

    &.doce-de-leite
        .active
            color: #f15a22
        .box
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f15a22+1,e34b1b+100 */
            background: rgb(241,90,34)
            background: -moz-linear-gradient(top, rgba(241,90,34,1) 1%, rgba(227,75,27,1) 100%)
            background: -webkit-linear-gradient(top, rgba(241,90,34,1) 1%,rgba(227,75,27,1) 100%)
            background: linear-gradient(to bottom, rgba(241,90,34,1) 1%,rgba(227,75,27,1) 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f15a22', endColorstr='#e34b1b',GradientType=0 )

    &.dulce-de-leche
        .active
            color: #f15a22
        .box
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f15a22+1,e34b1b+100 */
            background: rgb(241,90,34)
            background: -moz-linear-gradient(top, rgba(241,90,34,1) 1%, rgba(227,75,27,1) 100%)
            background: -webkit-linear-gradient(top, rgba(241,90,34,1) 1%,rgba(227,75,27,1) 100%)
            background: linear-gradient(to bottom, rgba(241,90,34,1) 1%,rgba(227,75,27,1) 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f15a22', endColorstr='#e34b1b',GradientType=0 )

    &.candy-store
        .active
            color: #f7941d
        .box
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7941d+0,e2891b+100 */
            background: #f7941d
            background: -moz-linear-gradient(top, #f7941d 0%, #e2891b 100%)
            background: -webkit-linear-gradient(top, #f7941d 0%,#e2891b 100%)
            background: linear-gradient(to bottom, #f7941d 0%,#e2891b 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7941d', endColorstr='#e2891b',GradientType=0 )

    &.confeitaria
        .active
            color: #f7941d
        .box
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7941d+0,e2891b+100 */
            background: #f7941d
            background: -moz-linear-gradient(top, #f7941d 0%, #e2891b 100%)
            background: -webkit-linear-gradient(top, #f7941d 0%,#e2891b 100%)
            background: linear-gradient(to bottom, #f7941d 0%,#e2891b 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7941d', endColorstr='#e2891b',GradientType=0 )

    &.bakery
        .active
            color: #f7941d
        .box
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7941d+0,e2891b+100 */
            background: #f7941d
            background: -moz-linear-gradient(top, #f7941d 0%, #e2891b 100%)
            background: -webkit-linear-gradient(top, #f7941d 0%,#e2891b 100%)
            background: linear-gradient(to bottom, #f7941d 0%,#e2891b 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7941d', endColorstr='#e2891b',GradientType=0 )

    &.confiteria
        .active
            color: #f7941d
        .box
            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f7941d+0,e2891b+100 */
            background: #f7941d
            background: -moz-linear-gradient(top, #f7941d 0%, #e2891b 100%)
            background: -webkit-linear-gradient(top, #f7941d 0%,#e2891b 100%)
            background: linear-gradient(to bottom, #f7941d 0%,#e2891b 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7941d', endColorstr='#e2891b',GradientType=0 )

.box
    position: relative
    top: 0
    opacity: 1

    @media ($smBreak)
        padding-top: 108%

    @media ($mdBreak)
        padding-top: 100%

    &:after
        position: absolute
        top: 0
        left: 0
        width: 0
        height: 100%
        content: ''
        background-color: #f7941d
        display: block
        transition: all 0.5s ease-in-out

.o-button-wrapper
    padding-bottom: 40px
    padding-right: 20px
    padding-left: 20px

.product-image
    position: absolute
    top: 35%
    width: auto
    height: auto
    max-width: 150px
    max-height: 180px
    z-index: 4
    transform: translate(50%, -50%)
    right: 40px
    transition: all 0.5s ease
    opacity: 0
    pointer-events: none

    &.active
        opacity: 1


    @media ($smBreak)
        top: 50%
        left: 0
        transform: translate(-50%, -50%)
        max-width: 50%
        max-height: 230px
    @media ($lgBreak)
        max-height: 320px

.category-selector
    padding: 40px 50px
    
    @media ($smBreak)
        position: absolute
        padding: 40px 80px
        top: 43%
        left: 28%
        transform: translateY(-50%)
        left: 35%
        width: 53%
        padding: 0

    @media ($mdBreak)
        right: 0
        left: auto

    @media ($xlgBreak)
        left: 40%

    li
        font-family: 'Block BE'
        font-size: 22px
        color: #FFFFFF
        text-transform: uppercase
        font-weight: bold
        position: relative
        opacity: 0.2
        transition: all 0.5s ease
        padding: 1%

        @media ($smBreak)
            font-size: 2.6vw
            padding: 9% 0

        @media ($mdBreak)
            font-size: 2.3vw
            line-height: 2.3vw

        @media ($xlgBreak)
            font-size: 1.77vw

        &:before
            transition: all 1s cubic-bezier(.87,-.41,.19,1.44)
            width: 0
            content: ''
            display: block
            width: 0
            height: 2px
            background-color: #FFFFFF
            position: absolute
            top: 50%
            transform: translateX(-50%)
            right: 99%

            @media ($smBreak)
                right: 105%

        &.hovered
            opacity: 0.5

            &:before
                width: 20px
                opacity: 0.5

                @media ($smBreak)
                    width: 17%

.main-button
    position: relative
    text-transform: uppercase
    text-decoration: none
    font-weight: 800
    font-size: 12px
    letter-spacing: 0.2em
    padding: 23px
    line-height: 1
    display: inline-block
    box-sizing: border-box
    z-index: 2
    top: -20px

    span
        position: relative
        z-index: 9

    &:after
        content: ''
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 0
        background-color: #f7941d
        z-index: 1
        transition: all 0.5s ease

    &:hover
        &:after
            top: 0
            height: 100%
    
    @media ($smBreak)
        position: absolute
        transform: translateY(-120%)
        top: 105%
        padding: 3% 2.5% 2.9% 2.5%
        box-shadow: 5px 4px 16px 0px rgba(54, 13, 6, 0.18)
    
    @media ($mdBreak)
        top: 66%
        box-shadow: 10px 10px 16px 0px rgba(54, 13, 6, 0.18)

    @media ($xlgBreak)
        top: 67%
        box-shadow: 20px 20px 36px 0px rgba(54,13,6,0.18)

.col
    &.transition-in
        .product-image
            left: 50%
            opacity: 0
        .box
            &:after
                width: 100%

    &.transition-out
        .box
            &:after
                width: 0%
                left: 100%

    &.transition-back
        .box
            &:after
                transition: none
                opacity: 0

</style>
