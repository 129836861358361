var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"main-header",class:{
    'mini' : _vm.scrollPosition > 50 && !_vm.menuOpen,
    'home' : _vm.isHome && _vm.scrollPosition <= 50,
    'menu-open' : _vm.menuOpen,
    'quick-menu' : _vm.menu.isQuickMenu,
    'hide' : _vm.hideHeader || !_vm.showLoading
  }},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col offset-xxs-1 xxs-4 sm-3 xlg-2",staticStyle:{"font-size":"0"}},[_c('router-link',{attrs:{"to":`/${_vm.$i18n.locale}`}},[_c('NeugebauerLogo',{staticClass:"main-logo"})],1)],1),_c('quick-nav',{staticClass:"menu-content col offset-xxs-1 offset-xlg-3 xxs-7 sm-6 xlg-4",on:{"clickQuickMenu":_vm.openMenu}}),_c('menu-burger',{staticClass:"col offset-xxs-1 offset-sm-2 offset-xlg-4 xxs-1 sm-2 xlg-1",on:{"clickMenu":_vm.openMenu}})],1),(_vm.menuOpen)?_c('menu-list',{attrs:{"type":_vm.menu.type},on:{"closeMenu":_vm.closeMenu}}):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }