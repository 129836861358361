<template>
  <div class="featured-recipes mb-xxs-15 mb-sm-12 mb-md-9">
    <div class="row flex-sm">
      <div class="o-recipes-text col offset-xxs-2 offset-xlg-3 xxs-12 sm-5 xlg-4 align-right-sm valign-middle">
        <h2 class="super-title f-orange mb-sm-8 mb-xxs-9">{{title}}</h2>


        <div class="u-relative">
          <slides-handler class="c-handler-recipes c-handler-recipes--mobile" @nextClicked="this.nextRecipe" @prevClicked="this.prevRecipe" />

          <!-- BGN: Recipe Title + Mobile Swiper -->
          <div class="recipe-infos">
            <div class="timeline"></div>
            <div class="swiper-wrapper">

              <div class="swiper-slide" v-for="(contentItem, i) in contentList" :key="i">
                <div class="recipe-title regular-title f-gray mb-sm-5 mb-xxs-5">{{contentItem.title}}</div>

                <ul class="recipe-info mb-xxs-12">
                  <li>
                    <ClockIcon/>
                    <span>{{contentItem.prep_time}}</span>
                  </li>
                  <li>
                    <PortionsIcon/>
                    <span>{{contentItem.n_portions}}</span>
                  </li>
                  <li>
                    <ChefIcon/>
                    <span>{{contentItem.level}}</span>
                  </li>
                </ul>

                <div class="square img bg-brown show-xxs hide-sm swiper-lazy" v-bind:style="getBackgroundImage(contentItem)">
                  <!-- <div class="swiper-lazy-preloader"></div> -->
                </div>

                <regular-button
                class="f-orange b-white bg-white clear mt-xxs-15 mt-sm-0 xxs-16 sm-auto"
                hasArrowIcon="true"
                hoverColor="#f15a22"
                borderColor="#fff"
                :link="`/${$i18n.locale}/receitas/${contentItem.slug}`"
                >{{ $t('buttons.access') }}</regular-button>

              </div>

            </div>
          </div>
          <!-- END: Recipe Title + Mobile Swiper -->
        </div>
      </div>

      <div class="col offset-xxs-2 offset-sm-1 xxs-12 sm-5 xlg-4 hide-xxs show-sm">
        <!-- BGN: Recipe Desktop Image Swiper -->
        <div class="u-relative">
          <slides-handler class="c-handler-recipes" @nextClicked="this.nextRecipe" @prevClicked="this.prevRecipe" />
          <div class="recipe-images">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(contentItem, i) in contentList" :key="i">
                <div class="square img bg-brown swiper-lazy" v-bind:style="getBackgroundImage(contentItem)">
                  <!-- <div class="swiper-lazy-preloader"></div> -->
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- END: Recipe Desktop Image Swiper -->
      </div>

    </div>

  </div>
</template>

<script>
import Swiper from "swiper";
import RegularButton from "../_general/RegularButton";
import SlidesHandler from "../_general/SlidesHandler";
// import RecipeIcon from "../../assets/imgs/recipe-icon.svg";
import ClockIcon from "../../assets/imgs/clock-icon.svg";
import PortionsIcon from "../../assets/imgs/portions-icon.svg";
import ChefIcon from "../../assets/imgs/chef-icon.svg";

export default {
  name: "featured-content-2",
  components: {
    RegularButton,
    SlidesHandler,
    // RecipeIcon,
    ClockIcon,
    PortionsIcon,
    ChefIcon,
  },
  props: ["title", "contentList"],
  data: function() {
    return {
      recipeInfos: {},
      recipeImages: {},
      link: ""
    };
  },
  methods: {
    nextRecipe: function() {
      this.recipeInfos.slideNext();
    },
    prevRecipe: function() {
      this.recipeInfos.slidePrev();
    },
    getBackgroundImage(contentItem) {
      return { backgroundImage: `url(${contentItem.thumbnail})` };
    },
  },
  mounted: function() {
    this.$nextTick(() => {
      //eslint-disable-next-line
      this.recipeInfos = new Swiper(".recipe-infos", {
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 1,
          loadOnTransitionStart: true,
          elementClass: 'swiper-lazy',
          loadingClass: 'swiper-lazy-loading',
          loadedClass: 'swiper-lazy-loaded',
          preloaderClass: 'swiper-lazy-preloader',
        },
        on: {
          slideChange: () => {
            this.recipeImages.slideTo(this.recipeInfos.activeIndex);
          },
          //eslint-disable-next-line
          lazyImageReady: (slideEl, imageEl) => {
            slideEl.querySelector('.swiper-lazy-preloader').style.display = 'none';
          }
        }
      });
      //eslint-disable-next-line
      this.recipeImages = new Swiper(".recipe-images", {
        preloadImages: false,
        lazy: {
          loadPrevNext: true,
          loadPrevNextAmount: 1,
          loadOnTransitionStart: true,
          elementClass: 'swiper-lazy',
          loadingClass: 'swiper-lazy-loading',
          loadedClass: 'swiper-lazy-loaded',
          preloaderClass: 'swiper-lazy-preloader',
        },
        on: {
          slideChange: () => {
            this.recipeInfos.slideTo(this.recipeImages.activeIndex);
          },
          //eslint-disable-next-line
          lazyImageReady: (slideEl, imageEl) => {
            slideEl.querySelector('.swiper-lazy-preloader').style.display = 'none';
          }
        }
      });
    });
  }
};
</script>

<style lang="sass" scoped>
@import '../../assets/sass/neug-grid/_variables'

.c-handler-recipes--mobile
  @media ($smBreak)
     display: none

.u-relative
  position: relative

.o-recipes-text
    padding-top: 125px

.recipe-images
    overflow: hidden
    position: relative

.recipe-infos
    .swiper-slide
        opacity: 0
        transition: all 0.5s ease

.recipe-infos
    .swiper-slide-active
        opacity: 1

.recipe-info
    vertical-align: middle

    li
        display: inline-block
        margin-left: 40px
        position: relative
        line-height: 1

        svg
            width: auto
            height: 120%
            fill: #f15a22
            position: absolute
            right: 112%
            top: 50%
            transform: translateY(-60%)

        span
            color: #6b3128
            font-size: 13px

            @media ($mdBreak)
                font-size: 15px

    li:first-child
        margin-left: 22px

.featured-recipes
    position: relative
    z-index: 2
    &:before
        content: ''
        background-color: #fbe6d5
        position: absolute
        width: 100%
        height: 95%
        top: 70px
        z-index: -1


.slides-handler-container
    position: absolute
    bottom: 145px
    z-index: 1

    @media ($xsBreak)
        bottom: 120px

    @media ($smBreak)
        top: 25%
        bottom: inherit

.recipe-icon
    position: absolute
    bottom: 145px
    z-index: 1

    @media ($xsBreak)
        bottom: 120px

    @media ($smBreak)
        top: 25%
        bottom: inherit

    svg
        position: absolute
        fill: #FFFFFF
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)
        height: 50%
        width: auto

.square
    &.img
        padding-top: 0
        height: 300px
        background-position: center center
        background-repeat: no-repeat
        background-size: cover
        
        @media ($smBreak)
            padding-top: 120%
            height: 0

        @media ($mdBreak)
            padding-top: 100%
            height: 0

</style>
