<template>
    <div class="container">
        <div class="row">
            <div v-for="i in segmentsQty" :key="i" :class="addVisibilityClass(i)" class="segment bg-orange col xxs-2">
                <ul class="icon-container">
                    <li class="icon">
                        <component v-once :is="icon[i-1]"/>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ChocolateIcon from '../../assets/imgs/chocolate-icon.svg'
import DoceLeiteIcon from '../../assets/imgs/doce-de-leite-icon.svg'
import CandyStoreIcon from '../../assets/imgs/candystore-icon.svg'
import store from '../../store'

export default {
    name: 'page-loader',
    props: {
        status: {
            default: false
        },
        dataFetched: {
            default: false
        }
    },
    components: {
        ChocolateIcon,
        DoceLeiteIcon,
        CandyStoreIcon
    },
    data: function() {
        return {
            segmentsQty: 8,
            addClassToKeyIn: 0,
            addClassToKeyOut: 1,
            direction: 'in',
            doneIn: false,
            iconAnimation: 0,
            iconsArr: [
                'ChocolateIcon',
                'DoceLeiteIcon',
                'CandyStoreIcon'
            ],
            icon: []
        }
    },
    watch: {
        doneIn: function() {
            this.hide()
        },
        dataFetched: function() {
            this.hide()
        },
        status: function() {
            if (this.status) {
                this.animateIn()
            } else {
                this.hide()
            }
        }
    },
    mounted: function() {
        this.animateIn()
        for (let index = 0; index < this.segmentsQty; index++) {
            this.icon.push(this.shuffleIcon())
        }

        setInterval(() => {
            let iconIndex = Math.floor(Math.random() * 8)
            let iconsArrIndex = Math.floor(Math.random() * 3)
            this.$set(this.icon, iconIndex, this.iconsArr[iconsArrIndex])
        }, 1000)
    },
    methods: {
        shuffleIcon: function() {
            let number = Math.floor(Math.random() * this.iconsArr.length)
            return this.iconsArr[number]
        },
        hide: function() {
            this.doneIn && !this.status && this.dataFetched && this.animateOut()
            this.doneIn && !this.status && this.dataFetched && setTimeout(() => {
                store.commit('SHOW_LOADING', false)
                clearInterval(this.interval)
            }, 1000)
        },
        addVisibilityClass: function(i) {
            let classes
            if (i <= this.addClassToKeyIn) {
                classes = "visible"
            }
            if (i <= this.addClassToKeyOut && this.direction === 'out') {
                classes = "hidden"
            }
            return classes
        },
        increaseClassKey: function() {
            this.interval = setInterval(() => {
                if (this.addClassToKeyIn <= this.segmentsQty && this.direction === 'in') {
                    this.addClassToKeyIn++
                }
                if (this.addClassToKeyOut <= this.segmentsQty && this.direction === 'out') {
                    this.addClassToKeyOut++
                }
                if (this.addClassToKeyIn == this.segmentsQty) {
                    this.doneIn = true
                    store.commit('ANIMATION_IN_FINISHED')
                    // clearInterval(this.interval)
                }
            }, 100)
        },
        animateIn: function() {
            this.direction = 'in'
            this.increaseClassKey()
        },
        animateOut: function() {
            clearInterval(this.interval)
            this.direction = 'out'
            this.increaseClassKey()
        }
    }
}
</script>

<style lang="sass" scoped>

.container
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    z-index: 9999
    overflow: hidden

.row
    height: 100%

.segment
    height: 100%
    top: -100%
    transition: all 0.5s ease
    border-right: 1px solid #dc4e19
    border-left: 1px solid #dc4e19
    position: relative

    &.visible
        top: 0

        .icon-container
            animation-name: loading
            animation-duration: 0.5s
            animation-timing-function: ease
            animation-iteration-count: infinite
            animation-direction: alternate

            @keyframes loading
                0%
                    top: 45%
                
                100%
                    top: 55%

    .icon-container
        width: 25%
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)

    .icon
        width: 100%
        padding-top: 100%
        position: relative

        svg
            position: absolute
            height: 100%
            width: auto
            top: 50%
            left: 50%
            transform: translate(-50%, -50%)
            fill: #FFFFFF

    &.hidden
        top: 100%

</style>
