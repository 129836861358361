export const bannerCookieDogma = (text) => {
    (function(win, doc) {
        var code   = 'RlacclOAhhjDz5mSubkv6qL8tGeFVoOv';
        var domain = 'neugebauer.com.br';
        var url    = 'https://dogma.' + domain;

        function unique() {
          var id = 'dogma-' + Math.random().toString(36).substr(2, 9);
          if(!doc.getElementById(id)) {
            return id;
          } else {
            return unique();
          }
        }

        function init() {
          if(!win.dogma) {
            console.log("Can't find 'window.dogma'");
            return;
          }

          var id   = unique();
          var root = doc.createElement('div');
          root.setAttribute('id', id);
          doc.body.appendChild(root);
          var options = {
            position : 'bottom',
            theme    : 'light',
            variant  : 'floating',
            // logo     : false,
            domain   : {
              code   : code,
              domain : domain
            },
            text,
            actions : ['cookies', 'inquiries', 'consent'],
            cookie  : {
              remove: {
                enabled: false,
                interval: 30000, //in millis
              }
            },
            // handlers: {
            //   cookie  : e => { /* console.log(e.event, e.data) */ },
            //   consent : e => { /* console.log(e.event, e.data) */ }
            // },
          };

          win.dogma.init(root, options);
        }

        var css    = url + '/dpc/asset/bundle.css?dm=' + code;
        var js     = url + '/dpc/asset/bundle.js?dm='  + code;

        var lnk = doc.createElement('link');
        lnk.setAttribute('rel' , 'stylesheet');
        lnk.setAttribute('href', css);
        doc.head.appendChild(lnk)

        var scrpt = doc.createElement('script');
        scrpt.setAttribute('src', js);
        scrpt.onload = init;
        doc.head.appendChild(scrpt)
      })(window, document);
}