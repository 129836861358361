<template>
  <div class="row">
    <div class="col offset-xxs-1 xxs-14">
      <div class="box bg-lightest-brown"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "box-news-and-recipes"
};
</script>

<style lang="sass" scoped>
@import '../../assets/sass/neug-grid/_variables'

.box
    display: block
    width: 100%
    position: relative
    z-index: -1
    margin-bottom: -320px
    margin-top: -260px
    height: 650px

    @media ($smBreak)
        padding-top: 65%
        margin-top: -21.5%
        margin-bottom: -29.5%
        height: 0

    @media ($mdBreak)
        margin-top: -19.5%
        margin-bottom: -24.5%
        padding-top: 56%

    @media ($lgBreak)
        margin-top: -19%

    @media ($xlgBreak)
        margin-top: -14.7%
        padding-top: 45.2%
        margin-bottom: -19.5%


</style>

