<template>
    <div class="background-grid">
        <div class="row">
            <div v-for="i in 8" :key="i" class="vertical-line col xxs-2"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'background-grid'
}
</script>

<style lang="sass" scoped>

    $borderColor: #fff5e9

    .background-grid
        height: 100px
        width: 100%
        position: fixed
        top: 0
        left: 0
        width: 100%
        height: 100%
        z-index: 1
        overflow: hidden
    
    .vertical-line
        height: 99999px

</style>
