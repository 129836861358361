import { render, staticRenderFns } from "./BoxNewsAndRecipes.vue?vue&type=template&id=4a028593&scoped=true"
import script from "./BoxNewsAndRecipes.vue?vue&type=script&lang=js"
export * from "./BoxNewsAndRecipes.vue?vue&type=script&lang=js"
import style0 from "./BoxNewsAndRecipes.vue?vue&type=style&index=0&id=4a028593&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a028593",
  null
  
)

export default component.exports