<template>
    <div @click="defineMenuType('main')" class="menu-burger align-right-xs">
        <ul>
            <li></li>
            <li></li>
            <li></li>
        </ul>
        <div class="text">Menu</div>
    </div>
</template>

<script>
export default {
    name: 'menu-burger',
    methods: {
        defineMenuType: function(type) {
            this.$emit('clickMenu', type)
        }
    }
}
</script>

