<template>
  <div class="menu-items row" :class="[{ 'open' : mounted }, type]">
    <button @click="closeMenu" class="close-button"></button>
    <transition name="hide">
      <ul class="langs absolute" v-if="type === 'general'">
        <li>
          <a href="/pt">
            <span>PT</span>
          </a>
        </li>
        <li>
          <a href="/en">
            <span>EN</span>
          </a>
        </li>
        <li>
          <a href="/es">
            <span>ES</span>
          </a>
        </li>
      </ul>
    </transition>
    <transition name="hide">
      <div class="columns-container col offset-xxs-1 offset-md-3 xxs-14 md-10">
        <div class="content-wrapper">
          <div class="scroll">
            <div class="swiper-wrapper">
              <div class="swiper-slide">

                <ul class="langs" v-if="type === 'general'">
                  <li>
                    <a href="/pt">
                      <span>PT</span>
                    </a>
                  </li>
                  <li>
                    <a href="/en">
                      <span>EN</span>
                    </a>
                  </li>
                  <li>
                    <a href="/es">
                      <span>ES</span>
                    </a>
                  </li>
                </ul>


                <!-- GENERAL -->
                <transition-group name="hide">

                  <template v-if="type === 'general'">
                    <div v-for="(column, indexColumn) in menuItems.general[$i18n.locale]" :key="indexColumn" class="col xxs-16" >

                      <ul class="c-list-nav">
                        <li v-for="(item, indexItem) in filteredSubcategories(column)" :key="indexItem" @click="closeMenu()" class="c-list-nav__item">
                          <router-link :to="`/${$i18n.locale}${indexColumn === 0 ? '' : '/produtos/' + column.slug}/${item.slug}`" v-if="!item.external" >
                            <span>{{item.name}}</span>
                          </router-link>
                          <a :href="`${item.slug}`" v-if="!!item.external">
                            <span>{{item.name}}</span>
                          </a>
                        </li>
                      </ul>

                    </div>
                  </template>

                </transition-group>

              </div>
            </div>
            <div class="swiper-scrollbar">
              <div class="swiper-scrollbar-drag"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import store from "../../store";

export default {
  name: "menu-list",
  props: ["type"],
  data: function() {
    return {
      mounted: false,
      scroll: {},
      menuItems: {
        general: {
          pt: [
            {
              name: "Menu",
              slug: "menu",
              subcategories: [
                {
                  name: "A marca",
                  slug: "a-marca",
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: "Utilidades",
                  slug: "utilidades",
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: 'Produtos',
                  slug: 'produtos',
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: "Fale Conosco",
                  slug: "fale-conosco",
                  uid: 0,
                  hide: false,
                  external: false
                },
                // {
                //   name: "Contatos Comerciais",
                //   slug: "contatos-comerciais",
                //   uid: 0,
                //   hide: false,
                //   external: false
                // },
                {
                  name: "Novidades",
                  slug: "novidades",
                  uid: 0,
                  hide: false,
                  external: false
                },
                // {
                //   name: "Quero revender",
                //   slug: "quero-revender",
                //   uid: 0,
                //   hide: false,
                //   external: false
                // },
                {
                  name: "Trabalhe Conosco",
                  slug: "https://neugebauer.gupy.io/",
                  uid: 0,
                  hide: false,
                  external: true
                },
                {
                  name: "Receitas",
                  slug: "receitas",
                  uid: 0,
                  hide: false,
                  external: false
                }
              ]
            }
          ],
          en: [
            {
              name: "Menu",
              slug: "menu",
              subcategories: [
                {
                  name: "The Brand",
                  slug: "a-marca",
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: "News",
                  slug: "novidades",
                  uid: 0,
                  hide: true,
                  external: false
                },
                {
                  name: "Recipes",
                  slug: "receitas",
                  uid: 0,
                  hide: true,
                  external: false
                },
                {
                  name: "Utilities",
                  slug: "utilidades",
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: "Contact us",
                  slug: "fale-conosco",
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: 'Products',
                  slug: 'produtos',
                  uid: 0,
                  hide: false,
                  external: false
                },
                // {
                //   name: "Business Contact",
                //   slug: "quero-revender",
                //   uid: 0,
                //   hide: false,
                //   external: false
                // },
              ]
            }
          ],
          es: [
            {
              name: "Menú",
              slug: "menu",
              subcategories: [
                {
                  name: "La Marca",
                  slug: "a-marca",
                  uid: 0,
                  hide: false,
                external: false
                },
                {
                  name: "Noticias",
                  slug: "novidades",
                  uid: 0,
                  hide: true,
                  external: false
                },
                {
                  name: "Recetas",
                  slug: "receitas",
                  uid: 0,
                  hide: true,
                  external: false
                },
                {
                  name: "Utilidades",
                  slug: "utilidades",
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: "Hable con nosotros",
                  slug: "fale-conosco",
                  uid: 0,
                  hide: false,
                  external: false
                },
                {
                  name: 'Productos',
                  slug: 'produtos',
                  uid: 0,
                  hide: false,
                  external: false
                },
                // {
                //   name: "Contacto Comercial",
                //   slug: "quero-revender",
                //   uid: 0,
                //   hide: false,
                //   external: false
                // },
              ]
            }
          ]
        }
      }
    };
  },
  mounted: function() {
    setTimeout(() => {
      this.mounted = true;
    }, 100);

    //Lang reposition
    setTimeout(() => {
      this.defineLangPosition();
    }, 600);
    let resizeTimer;
    window.onresize = () => {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        this.defineLangPosition();
      }, 100);
    };
  },
  methods: {
    closeMenu: function() {
      this.$emit("closeMenu");
    },
    filteredSubcategories(column) {
    if (column && column.subcategories) {
      return column.subcategories.filter(item => !('hide' in item) || item.hide === false);
    }
    return [];
  },
    defineLangPosition: function() {
      if ( !this.isSearching && this.type === "general" && store.state.menuOpen ) {
        const swiperSlideTop = document
          .querySelector(".columns-container .swiper-slide")
          .getBoundingClientRect().top;
        const langsEl = document.querySelector(".langs.absolute");
        langsEl.style.top = `${swiperSlideTop}px`;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
@import '../../assets/sass/neug-grid/_variables'

.c-list-nav
    display: flex
    flex-wrap: wrap
    justify-content: center
    margin-top: 15px
    @media ($mdBreak)
        justify-content: flex-start
    &__item
        width: 100%
        font-weight: bold
        @media ($mdBreak)
            width: 50%
        a
            span
                font-size: 17px
                @media ($mdBreak)
                    font-size: 24px

.content-wrapper
    height: 100%
    overflow: hidden
    position: relative

.menu-items
    position: fixed
    height: 100%
    width: 100%
    background-color: #f27d1d
    top: -100%
    left: 0
    z-index: 2
    transition: all 0.5s ease

    &.general
        background-image: url('../../assets/imgs/menu-bg.jpg')
        background-size: cover
        background-position: center center

    &.open
        top: 0

    ul
        margin-bottom: 30px
        text-align: center

        @media ($smBreak)
            text-align: left

    li
        color: #FFFFFF
        text-transform: uppercase
        margin-bottom: 12px
        font-size: 15px

        a
            text-decoration: none
            color: #FFFFFF
            position: relative
            transition: all 0.5s ease

            &:hover
                color: #6b3127


            span
                position: relative
                z-index: 2
                font-family: Block BE
                font-weight: 500


        @media ($lgBreak)
            font-size: 15px
            margin-bottom: 10px

        &:last-child
            padding-bottom: 40px

        &.column-title
            color: #6b3128
            font-weight: bold
            font-family: 'Block BE'
            font-size: 24px
            margin-bottom: 10px
            transition: all 0.5s ease

            @media ($lgBreak)
                font-size: 24px
                margin-bottom: 30px
    .swiper-slide
      ul:first-child
        li
          a
            span
              margin-left: 4px


.columns-container
    position: absolute
    height: 100%
    overflow: hidden
    padding-top: 80px
    padding-bottom: 58px

    @media ($smBreak)
        padding-top: 120px
        padding-bottom: 120px
        padding-right: 160px

    @media (min-width: 1200px)
        padding-top: 180px
        padding-bottom: 180px
        padding-right: 180px

    @media ($smBreak)
        .col
            &:last-child
                ul
                    margin-bottom: 0

.close-button
    border-color: #FFFFFF
    border-radius: 50%
    position: absolute
    top: 20px
    right: 20px
    z-index: 9999

    &:after,
    &:before
        background-color: #FFFFFF

.scroll
    height: 100%
    position: relative

.swiper-slide
    height: auto
    margin: auto

.swiper-container-free-mode
    > .swiper-wrapper
        align-items: center
        flex-direction: row

.search-container
    display: inline-block
    position: absolute
    bottom: 0
    left: 0
    width: 100%
    background-color: #f7941d
    z-index: 999
    transition: all 0.3s ease
    padding: 0 6.25%
    box-sizing: border-box
    height: 60px
    padding-top: 18px

    @media ($smBreak)
        bottom: 50px
        background-color: transparent
        height: 40px

    @media (min-width: 1200px)
        bottom: 115px

    &.open
        bottom: 100%
        height: 100%
        transform: translateY(100%)
        padding-top: 80px
        padding-bottom: 90px

        @media ($smBreak)
            padding-top: 100px

        @media ($mdBreak)
            padding-top: 110px

        @media ($lgBreak)
            padding-top: 140px

        @media ($xlgBreak)
            padding-top: 180px

        .results
            opacity: 1
            height: 100%

.input-container
    position: relative
    display: inline-block

    svg
        position: absolute
        top: 1px
        left: 0
        height: 80%
        width: auto
        fill: #FFFFFF

    .search-input
        font-family: 'Block BE'
        font-weight: bold
        font-size: 18px
        background: transparent
        color: #6b3128
        border: 0
        outline: 0
        padding-left: 27px
        position: relative

        @media ($lgBreak)
            font-size: 20px

        &::placeholder
            color: #6b3128

.langs
    margin-bottom: 0
    transition: all 0.5s ease
    top: 0

    @media ($smBreak)
        display: none

    &.absolute
        position: absolute
        right: 14.25%
        z-index: 999
        display: none

        @media ($smBreak)
            display: block

    li
        display: inline-block
        margin-bottom: 0
        position: relative

        &:after
            content: '.'
            padding: 0 10px 10px
            font-size: 14px
            color: #6b3128
            vertical-align: middle
            display: inline-block

        &:last-child
            &:after
                display: none

        a
            text-decoration: none
            color: #FFFFFF

</style>
