<template>

    <router-link
        v-if="link"
        :to="`${link}`"
        class="outline-button"
        :class="{ 'full-size' : fullSize, 'big' : size == 'big', 'regular' : size == 'regular' }">
            <span><slot></slot></span>
            <div class="hover" :style="{ backgroundColor: hoverColor }"></div>
        <ArrowRightIcon
            class="arrow-right"
            v-if="hasArrowIcon"/>
    </router-link>

    <button
        v-else
        type="submit"
        class="outline-button"
        :class="{ 'full-size' : fullSize, 'big' : size == 'big', 'regular' : size == 'regular' }">
        <span><slot></slot></span>
        <div class="hover" :style="{ backgroundColor: hoverColor }"></div>
        <ArrowRightIcon
            class="arrow-right"
            v-if="hasArrowIcon"/>
    </button>
    
</template>


<script>
import ArrowRightIcon from '../../assets/imgs/arrow-right-icon.svg'
export default {
    name: 'regular-button',
    props: ['size', 'hasArrowIcon', 'fullSize', 'link', 'hoverColor'],
    components: {
        ArrowRightIcon
    },
}
</script>

<style lang="sass" scoped>
@import '../../assets/sass/neug-grid/_variables'

.outline-button
    border-width: 4px
    border-style: solid
    display: inline-block
    font-size: 12px
    padding: 12px 22px 10px 22px
    text-transform: uppercase
    font-weight: 800
    cursor: pointer
    white-space: nowrap
    box-sizing: border-box
    position: relative
    transition: all 0.4s ease
    appearance: none
    background-color: transparent
    font-family: 'Avenir'
    position: relative
    z-index: 10

    @media ($smBreak)
        padding: 9px 17px 7px 17px

    @media ($mdBreak)
        padding: 12px 22px 10px 22px

    @media ($lgBreak)
        font-size: 13px
        padding: 14px 10px 14px 30px

    span
        display: inline-block
        position: relative
        z-index: 9999
        transition: all 0.5s ease
        &:after
            content: ''
            width: 30px
            height: 1px
            display: inline-block
            z-index: 9999

    .hover
        position: absolute
        bottom: 0
        left: 0
        width: 100%
        height: 0%
        transition: all 0.5s ease


    &:hover
        span
            color: #FFFFFF

        .arrow-right
            fill: #FFFFFF

        .hover
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
    &.t-white 
        color: #fff

.arrow-right
    width: 6px
    height: auto
    position: absolute
    top: 50%
    right: 20px
    transform: translateY(-50%)
    z-index: 999
    transition: all 0.5s ease

.full-size
    width: 100%

    .arrow-right
        float: right
</style>
