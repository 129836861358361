<template>
  <header
    class="main-header"
    :class="{
      'mini' : scrollPosition > 50 && !menuOpen,
      'home' : isHome && scrollPosition <= 50,
      'menu-open' : menuOpen,
      'quick-menu' : menu.isQuickMenu,
      'hide' : hideHeader || !showLoading
    }"
  >
    <div class="container-fluid">
      <div class="row">
        <div class="col offset-xxs-1 xxs-4 sm-3 xlg-2" style="font-size: 0">
          <router-link :to="`/${$i18n.locale}`">
            <NeugebauerLogo class="main-logo"/>
          </router-link>
        </div>
        <quick-nav
          @clickQuickMenu="openMenu"
          class="menu-content col offset-xxs-1 offset-xlg-3 xxs-7 sm-6 xlg-4"
        />
        <menu-burger
          @clickMenu="openMenu"
          class="col offset-xxs-1 offset-sm-2 offset-xlg-4 xxs-1 sm-2 xlg-1"
        ></menu-burger>
      </div>
      <menu-list @closeMenu="closeMenu" v-if="menuOpen" :type="menu.type"/>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import store from "../../store";
import QuickNav from "./_QuickNav";
import MenuBurger from "./_MenuBurger";
import MenuList from "./_MenuList";
import NeugebauerLogo from "../../assets/imgs/logo-neugebauer.svg";

export default {
  name: "main-header",
  components: {
    QuickNav,
    MenuBurger,
    MenuList,
    NeugebauerLogo,
},
props: {
    isHome: Boolean
},
data: function() {
    return {
      scrollPosition: null,
      menu: {
        isOpen: false,
        isQuickMenu: false,
        clicked: "",
        list: [],
        type: null
      }
    };
  },
  methods: {
    updateScrollPosition: function() {
      this.scrollPosition = window.scrollY;
    },
    openMenu: function(menuType) {
      if (
        menuType == "chocolate" ||
        menuType == "doce-de-leite" ||
        menuType == "candy-store"
      ) {
        this.menu.isQuickMenu = true;
        this.menu.type = menuType;
      } else {
        this.menu.type = "general";
      }
      store.commit("OPEN_MENU");
    },
    closeMenu: function() {
      store.commit("OPEN_MENU", false);
      this.menu.isQuickMenu = false;
    }
  },
  mounted: function() {
    window.addEventListener("scroll", this.updateScrollPosition);
  },
  computed: mapState(["hideHeader", "menuOpen", "showLoading"])
};
</script>


<style lang="sass">
@import '../../assets/sass/neug-grid/_variables'

.main-header
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: 4
    transition: all 0.5s ease
    padding-top: 15px
    padding-bottom: 15px

    @media ($lgBreak)
        padding-top: 30px
        padding-bottom: 20px

    @media ($xlgBreak)
        padding-top: 50px
        padding-bottom: 40px

    .menu-content
        @media (min-width: 1200px)
            width: 30%
            margin-left: 14.75%

    .chocolate
        color: #6b3128

        svg
            fill: #6b3128

    .doce-de-leite
        color: #f15a22

        svg
            fill: #f15a22

    .candy-store
        color: #f7941d

        svg
            fill: #f7941d
            
    //_MenuBurger
    
    .menu-burger
        margin-top: 2.3%
        cursor: pointer
        transition: all 0.5s ease

        @media ($smBreak)
            margin-top: 2.8%

        @media ($lgBreak)
            margin-top: 34px

        @media (min-width: 1200px)
            margin-left: 24%

        &:hover
            ul
                margin-top: -5px

                li
                    margin-bottom: 9px

        .text
            text-transform: uppercase
            font-family: 'Block BE'
            font-size: 13px
            float: right
            margin-top: -1px
            margin-right: 10px
            transition: all 0.5s ease
            color: #f15a22
            display: none
            font-weight: normal

            @media ($smBreak)
                display: block

            @media ($lgBreak)
                font-size: 15px

        ul
            float: right
            width: 18px
            transition: all 0.5s ease

            @media ($lgBreak)
                width: 23px

            li
                width: 100%
                background-color: #f15a22
                border-radius: 4px
                margin-bottom: 3px
                transition: all 0.5s ease
                height: 3px

                @media ($lgBreak)
                    height: 4px

    .main-logo
        width: 90px
        height: auto
        transition: all 0.5s ease
        fill: #f15a22
        z-index: 3
        position: relative

        @media ($xsBreak)
            width: 100px

        @media ($smBreak)
            width: 120px

        @media ($mdBreak)
            width: 140px

        @media ($lgBreak)
            width: 160px

        @media ($xlgBreak)
            width: 180px

    &.home,
    &.menu-open
        .text
            color: #FFFFFF

        .chocolate,
        .doce-de-leite,
        .candy-store
            color: #FFFFFF

            svg
                fill: #FFFFFF

        .menu-burger
            color: #FFFFFF

            li
                background-color: #FFFFFF

        .main-logo
            fill: #FFFFFF

        .quick-nav-item 
            .img-container
                &:after
                    background-color: #FFF
    &.mini
        background-color: #FFFFFF
        padding: 15px 0 7px 0

        .nav-name
            font-size: 0

        .main-logo
            fill: #f15a22
            width: 90px

            @media ($xsBreak)
                width: 100px

            @media ($smBreak)
                width: 100px

            @media ($mdBreak)
                width: 110px

            @media ($lgBreak)
                width: 120px

            @media ($xlgBreak)
                width: 130px

        .menu-burger
            color: #f15a22
            margin-top: 10px

            @media ($xsBreak)
                margin-top: 12px

            @media ($smBreak)
                margin-top: 12px

            @media ($mdBreak)
                margin-top: 14px

            @media ($lgBreak)
                margin-top: 17px

            @media ($xlgBreak)
                margin-top: 17px

            li
                background-color: #f15a22

        .img-container
            height: 30px

            @media ($smBreak)
                height: 32px

            @media ($mdBreak)
                height: 35px
                margin-top: 5px

            @media ($lgBreak)
                height: 36px
                margin-bottom: 12px

            @media ($xlgBreak)
                height: 40px
                margin-bottom: 0

        .quick-nav-item
            margin-top: 4px

            @media ($smBreak)
                margin-top: 4px

            @media ($mdBreak)
                margin-top: 0px

            @media ($lgBreak)
                margin-top: 0px

    &.quick-menu
        nav
            position: relative
            z-index: 3

            .quick-nav-item
                opacity: 0.3
                
                &.clicked
                    opacity: 1

    &.hide
        top: 0
        // top: -100px
        @media ($smBreak)
            top: 0

</style>
