export function initChat() {
    let VI_API = {};
    VI_API.license = 'VI-88888737-1';
    VI_API.source = 1;
    VI_API.channel = 1;

    // let viScript = document.createElement('script');
    // viScript.type = 'text/javascript';
    // viScript.async = true;
    // viScript.src = 'https://neugebauer.virtualinteractions.com.br/script/tracking.js?ms=' + new Date().getTime();

    // var firstScript = document.getElementsByTagName('script')[0];
    // firstScript.parentNode.insertBefore(viScript, firstScript);
}