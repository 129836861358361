<template>
  <nav class="clear">
    
    <router-link
      v-for="(quickNavItem, i) in quickNavItems[$i18n.locale]"
      :key="i"
      class="quick-nav-item"
      :class="quickNavItem.id"
      :to="`/${$i18n.locale}/produtos/${quickNavItem.slug}/topo`"
    >
      <div class="img-container">
        <component :is="quickNavItem.icon"/>
      </div>
      <div class="nav-name">{{ quickNavItem.name }}</div>

    </router-link>


  </nav>
</template>

<script>
import ChocolateIcon from "../../assets/imgs/chocolate-icon.svg";
import DoceLeiteIcon from "../../assets/imgs/doce-de-leite-icon.svg";
import CandyStoreIcon from "../../assets/imgs/candystore-icon.svg";

export default {
  name: "quick-nav",
  data: function() {
    return {
      quickNavItems: {
        pt: [
          {
            name: "Chocolates",
            icon: ChocolateIcon,
            slug: "chocolates",
            id: 'chocolate'
          },
          {
            name: "Doce de Leite",
            icon: DoceLeiteIcon,
            slug: "doce-de-leite",
            id: 'doce-de-leite'
          },
          {
            name: "Confeitaria",
            icon: CandyStoreIcon,
            slug: "confeitaria",
            id: 'candy-store'
          }
        ],
        en: [
          {
            name: "Chocolates",
            icon: ChocolateIcon,
            slug: "chocolates",
            id: 'chocolate'
          },
          {
            name: "DULCE DE LECHE",
            icon: DoceLeiteIcon,
            slug: "dulce-de-leche",
            id: 'doce-de-leite'
          },
          {
            name: "Bakery",
            icon: CandyStoreIcon,
            slug: "bakery",
            id: 'candy-store'
          }
        ],
        es: [
          {
            name: "Chocolates",
            icon: ChocolateIcon,
            slug: "chocolates",
            id: 'chocolate'
          },
          {
            name: "DULCE DE LECHE",
            icon: DoceLeiteIcon,
            slug: "dulce-de-leche",
            id: 'doce-de-leite'
          },
          {
            name: "Confitería",
            icon: CandyStoreIcon,
            slug: "confiteria",
            id: 'candy-store'
          }
        ]
      },
      clickedItem: ""
    };
  }
};
</script>

<style lang="sass">
@import '../../assets/sass/neug-grid/_variables'

.quick-nav-item
    width: 33.3%
    float: left
    text-align: center
    text-decoration: none
    color: inherit
    transition: all 0.5s ease

    .img-container
        width: 100%
        position: relative
        margin-bottom: 18px
        transition: all 0.5s ease
        margin-bottom: 8%
        height: 30px

        @media ($smBreak)
            height: 45px

        @media ($mdBreak)
            height: 45px

        @media ($lgBreak)
            height: 45px
            margin-bottom: 12px

        @media ($xlgBreak)
            height: 55px
            margin-bottom: 18px

        &:after
            content: ''
            display: block
            width: 3px
            height: 3px
            border-radius: 50%
            background-color: #f9b96d
            position: absolute
            top: 50%
            transform: translate(50%, -50%)
            right: 0

            @media ($smBreak)
                width: 5px
                height: 5px

    &:last-child
        .img-container
            &:after
                display: none

    svg
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%)
        height: 100%
        width: auto
        transition: all 0.5s ease

    &:hover
        svg
            animation-name: jumping
            animation-iteration-count: infinite
            animation-duration: 1s
            animation-timing-function: ease

            @keyframes jumping
                0%
                    top: 0px

                50%
                    top: 5px

                100%
                    top: 0px

    .nav-name
        text-transform: uppercase
        font-weight: 900
        font-size: 10px
        letter-spacing: 0.15em
        transition: font-size 0.5s ease
        display: none

        @media ($mdBreak)
            display: block

        @media ($lgBreak)
            font-size: 12px

</style>


